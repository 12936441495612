import React, { useState, useEffect } from "react";
// components
import Layout from "../../layout/Layout";
import Box from "@mui/material/Box";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { MuiDataGrid, PopUpModel, EyeInfo } from "../../components";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ModalCloseBtn from "../../components/common/ModalCloseBtn";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormSubmitButton } from "../../components";
import { Formik } from "formik";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import {MuiDataGridForSummary,GridCellExpand,CommonCurdPopUp} from "../../components";
import { format, subDays } from "date-fns";
import SpeedoMeter from "../../components/common/speedo-meter/SpeedoMeter";
import FormHelperText from "@mui/material/FormHelperText";

import {
  getRatingByclientId,
  resetErrMessage,
  resetMessage,
  getLatestOutcomesForClient,
  getPreviousOutcomeData,
  submitPreviousOutcomesForClients,
  acceptByClientHandle,
  shareWithManagerOutcomes,
  shareWithHrOutcomes,
  updateImpactUpdatesDescription,
  rowSelect,
  getToogleData,
  updateEngagementNotes,
  updateCheckinRating,
  updateClientRating,
  emptyingArray,
} from "../../features/ratings/ratingSlice";
import { getEngagementDetails } from "../../features/dashboard/dashBoardSlice";

import { useSelector, useDispatch } from "react-redux";
import { Grid, CircularProgress, useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { isEmptyApprovedData } from "../../features/auth/authSlice";

import HowToUse from "../../components/common/HowToUse";

const DonutMemo = React.memo(({ dataReports }) => {
  return <Doughnut data={dataReports} options={dataReports.options} />;
});

const Assesment = () => {
  const [startDate, setStartDate] = useState(null);
  const [showUpdateDescription, setShowUpdateDescription] = useState(false);
  const [showUpdateEngagementNotes, setShowUpdateEngagementNotes] =
    useState(false);
  const [newstartDate, newSetStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [generateRowCheckId, setGenerateRowCheckId] = useState(null);
  const [checkInRating, setCheckinRating] = useState(null);
  const [checkinValue, setCheckinValue] = useState("");
  const [commentArr, setCommentArr] = useState([]);
  const [commentEdit, setCommentEdit] = useState(false);
  const [storeId, setStoreId] = useState(null);

  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );

  const [dateObj, setDateObj] = useState({
    lastmeeting: "",
    managerTouchPoint: "",
    hrPartnerTouchPoint: "",
  });

  const {
    loading,
    message,
    errMessage,
    clientRatingData,
    previousOutcomeData,
    selectedData,
    clientToggleData,
  } = useSelector((state) => ({ ...state.clientRating }));

  const { engagementsData } = useSelector((state) => ({
    ...state.clientEngagement,
  }));

  const dispatch = useDispatch();

  const marks = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "50",
      label: "1",
    },
    {
      value: "100",
      label: "2",
    },
    {
      value: "150",
      label: "3",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleUpdateMentorRating = (id, comment, rating) => {
    const body = {
      id: id,
      comment: comment,
      rating: rating ? rating : 1,
    };
    dispatch(updateClientRating(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        let result =
          commentArr.length > 0 && !commentEdit
            ? commentArr[0].ratings.length > 0 &&
              commentArr[0]?.ratings?.map((data) => {
                if (data.id === id) {
                  return {
                    ...data,
                    disabled: true,
                  };
                }
                return data;
              })
            : commentArr.length > 0 &&
              commentArr.map((data) => {
                if (data.id === id) {
                  return {
                    ...data,
                    disabled: true,
                  };
                }
                return data;
              });
        setCommentArr(result);
        setCommentEdit(true);
        setTimeout(() => {
          dispatch(resetMessage());

          // dispatch(getAssesmentImprovement());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };
  // const mactchesReport = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const useStyles = makeStyles((theme) => ({
    previousSummaryClass: {
      [theme.breakpoints.down("md")]: {
        width: "15rem!important",
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "50% !important",
        marginTop: "1rem",
      },
    },
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll",
      },
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    ratingParentOne: {
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
        fontWeight: 700,
        width: "100% !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
        fontWeight: 700,
        width: "100% !important",
      },
    },
    ratingParentClass: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column !important",
        fontFamily: "arial",
        marginTop: "2rem",
        paddingBottom: "1rem !important",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexDirection: "row !important",
        fontFamily: "arial",
        marginTop: "2rem",
        paddingBottom: "1rem !important",
      },
    },
    ratingParentTwo: {
      [theme.breakpoints.down("md")]: {
        // marginLeft: "2rem", // removed because it is coming outside the Grid
        display: "flex",
        width: "100%",

        flexDirection: "column",
        fontFamily: "arial",
      },
      [theme.breakpoints.up("sm")]: {
        // marginLeft: "2rem",// removed because it is coming outside the Grid
        display: "flex",
        width: "100%",

        flexDirection: "column",
        fontFamily: "arial",
      },
      "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffffff",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(getLatestOutcomesForClient());
    dispatch(getEngagementDetails());
    dispatch(emptyingArray());
  }, []);

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);
    setGenerateRowId(e.target.value);
  };

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
    dispatch(getPreviousOutcomeData());
  };

  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };
  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : "";
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientProgressReviewId: generateRowId,
    };

    dispatch(submitPreviousOutcomesForClients(body)).then((res) => {
      closeShowModelGenerate();
      setGenerateRowCheckId(generateRowId);
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          // dispatch(getAssesmentImprovement());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  useEffect(() => {
    newSetStartDate(
      engagementsData?.clientEngagement?.length > 0 &&
        engagementsData?.clientEngagement[0]?.engagementStartDate
        ? format(
            // subDays(
            new Date(
              engagementsData?.clientEngagement?.length > 0 &&
                engagementsData?.clientEngagement[0]?.engagementStartDate
            ),
            // 1 // Subtract 1 day
            // ),
            "MM-dd-yyyy"
          )
        : ""
    );
    setEndDate(
      clientRatingData.length > 0 &&
        clientRatingData[0].ratingData[0].ratingFromDate
        ? format(
            subDays(
              new Date(
                clientRatingData.length > 0 &&
                  clientRatingData[0].ratingData[0].ratingToDate
              ),
              1
            ),
            "yyyy-MM-dd"
          )
        : ""
    );
  }, [clientRatingData]);

  const handleShareHrClick = () => {
    let body = {
      clientProgressReviewId: clientRatingData[0]?.ratingData[0]?.id,
    };
    dispatch(shareWithHrOutcomes(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          generateRowCheckId
            ? dispatch(
                submitPreviousOutcomesForClients({
                  clientProgressReviewId: generateRowCheckId,
                })
              )
            : dispatch(getLatestOutcomesForClient());
        }, 1000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleShareManagerClick = () => {
    let body = {
      clientProgressReviewId: clientRatingData[0]?.ratingData[0]?.id,
    };
    dispatch(shareWithManagerOutcomes(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          generateRowCheckId
            ? dispatch(
                submitPreviousOutcomesForClients({
                  clientProgressReviewId: generateRowCheckId,
                })
              )
            : dispatch(getLatestOutcomesForClient());
        }, 1000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleAcceptByClient = () => {
    let body = {
      clientProgressReviewId: clientRatingData[0]?.ratingData[0]?.id,
    };
    dispatch(acceptByClientHandle(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(isEmptyApprovedData());
          dispatch(getLatestOutcomesForClient());

          // dispatch(getAssesmentManagerButton());
          // dispatch(getAssesmentHrButton());
        }, 1000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setStartDate(
      clientRatingData?.[0]?.ratingData?.[0]?.ratingFromDate
        ? format(
            // subDays(
            new Date(clientRatingData[0].ratingData[0].ratingFromDate),
            // 1 // Subtract 1 day
            // ),
            "yyyy-MM-dd"
          )
        : ""
    );
    setEndDate(
      clientRatingData.length > 0 &&
        clientRatingData[0].ratingData[0].ratingFromDate
        ? format(
            // subDays(
            new Date(
              clientRatingData.length > 0 &&
                clientRatingData[0].ratingData[0].ratingToDate
            ),
            // 1
            // ),
            "yyyy-MM-dd"
          )
        : ""
    );
  }, [clientRatingData]);

  const SubmitByHr = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareHrClick}
        disabled={
          clientRatingData.length > 0 &&
          clientRatingData[0]?.ratingData[0]?.isClientApproved &&
          clientRatingData.length > 0 &&
          !clientRatingData[0]?.ratingData[0]?.isSharedWithHR
            ? null
            : "disabled"
        }
      >
        {clientRatingData.length > 0 &&
        clientRatingData[0]?.ratingData[0]?.isClientApproved &&
        clientRatingData.length > 0 &&
        !clientRatingData[0]?.ratingData[0]?.isSharedWithHR
          ? "Share With HR"
          : "Shared With HR"}
      </Button>
    );
  };

  const SubmitByManager = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareManagerClick}
        disabled={
          clientRatingData.length > 0 &&
          clientRatingData[0]?.ratingData[0]?.isClientApproved &&
          clientRatingData.length > 0 &&
          !clientRatingData[0]?.ratingData[0]?.isSharedWithManager
            ? null
            : "disabled"
        }
      >
        {clientRatingData.length > 0 &&
        clientRatingData[0]?.ratingData[0]?.isClientApproved &&
        clientRatingData.length > 0 &&
        !clientRatingData[0]?.ratingData[0]?.isSharedWithManager
          ? "Share With Manager"
          : "Shared With Manager"}
      </Button>
    );
  };

  const AcceptOutcome = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAcceptByClient}
        disabled={
          clientRatingData.length > 0 &&
          clientRatingData[0]?.ratingData[0]?.isClientApproved
            ? "disabled"
            : null
        }
      >
        {clientRatingData.length > 0 &&
        clientRatingData[0]?.ratingData[0]?.isClientApproved
          ? "Approved Updates"
          : "Approve Updates"}
      </Button>
    );
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  useEffect(() => {
    setCommentArr(clientRatingData);
    setCommentEdit(false);
  }, [clientRatingData]);

  const handleCommentChange = (event, id) => {
    let result =
      commentArr.length > 0 && !commentEdit
        ? commentArr[0].ratings.length > 0 &&
          commentArr[0]?.ratings?.map((data) => {
            if (data.id === id) {
              return {
                ...data,
                comment: event.target.value,
                disabled: false,
              };
            }
            return data;
          })
        : commentArr.length > 0 &&
          commentArr.map((data) => {
            if (data.id === id) {
              return {
                ...data,
                comment: event.target.value,
                disabled: false,
              };
            }
            return data;
          });
    setCommentArr(result);
    setCommentEdit(true);
    setStoreId(id);
  };
  const handleSliderChange = (event, id) => {
    let result =
      commentArr?.length > 0 && !commentEdit
        ? commentArr[0]?.ratings?.length > 0 &&
          commentArr[0]?.ratings.map((data) => {
            if (data.id === id) {
              return {
                ...data,
                rating: event.target.value,
                disabled: false,
              };
            }
            return data;
          })
        : commentArr.length > 0 &&
          commentArr.map((data) => {
            if (data.id === id) {
              return {
                ...data,
                rating: event.target.value,
                disabled: false,
              };
            }
            return data;
          });
    setCommentArr(result);
    setCommentEdit(true);
    setStoreId(id);
    // setCommentTwoEdit(false)
  };

  const handleUpdateRating = (val) => {
    let data = {
      // excoCustomerUserId: clientName?.id,
      rating: val,
    };
    dispatch(updateCheckinRating(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleCheckinChange = async (e) => {
    setCheckinValue(e.target.value);
    if (e.target.value === "Red") {
      setCheckinRating(150);
    } else if (e.target.value === "Yellow") {
      setCheckinRating(450);
    } else if (e.target.value === "Green") {
      setCheckinRating(750);
    }

    await dispatch(
      handleUpdateRating(
        (e.target.value === "Red" && 1) ||
          (e.target.value === "Yellow" && 2) ||
          (e.target.value === "Green" && 3)
      )
    );
  };

  const dataThree = {
    rows: clientRatingData?.length > 0 && clientRatingData[0]?.ratingNote,
    columns: [
      {
        field: "engagementNote",
        headerName: "Engagement Notes",
        width: 450,
        renderCell: renderCellExpand,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: clientRatingData?.length > 0 && clientRatingData[0]?.ratingCfa,
    columns: [
      {
        field: "progressDescription",
        headerName: "Description",
        width: 800,
        renderCell: renderCellExpand,
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const HowToUseContent = `
If your organization requests leadership coaching impact updates, your Mentor/Coach will use this page to 
generate and share these updates with you for review and approval.`;

  const HowToUseDescription = `
Once a new Impact Update is sent to you for review, both you and your Mentor/Coach can continue making any 
edits to the form as needed. After the content is finalized, you will need to approve the update by clicking the
 “Approve” button at the bottom of the page.`;

  const HowToUseDescription1 = `
After approval, you can manually share the Impact Update with your Manager and/or HR Partner(s) by clicking the “Share” buttons.
 They will then be able to view the updates from the “Impact Summary” tab`;

  useEffect(() => {
    setDateObj({
      lastmeeting:
        clientRatingData?.length > 0 &&
        clientRatingData[0].ratingData[0].dateOfLastMeeting
          ? format(
              // subDays(
              new Date(clientRatingData[0]?.ratingData[0]?.dateOfLastMeeting),
              // 1
              // ),
              "yyyy-MM-dd"
              // ),
              // 1
            )
          : "",
      managerTouchPoint:
        clientRatingData?.length > 0 &&
        clientRatingData[0].ratingData[0].dateOfLastManagerTouchPoint
          ? format(
              // subDays(
              new Date(
                clientRatingData[0]?.ratingData[0]?.dateOfLastManagerTouchPoint
              ),
              // 1
              // ),
              //   1
              // ),
              "yyyy-MM-dd"
            )
          : "",
      hrPartnerTouchPoint:
        clientRatingData?.length > 0 &&
        clientRatingData[0].ratingData[0].dateOfLastHrTouchPoint
          ? format(
              // subDays(
              new Date(
                clientRatingData[0]?.ratingData[0]?.dateOfLastHrTouchPoint
              ),
              // 1
              // ),
              //   1
              // ),
              "yyyy-MM-dd"
            )
          : "",
    });
  }, [clientRatingData]);

  useEffect(() => {
    let result =
      clientRatingData.length > 0 &&
      clientRatingData[0]?.ratingData[0]?.engagementCheckInRating &&
      clientRatingData[0]?.ratingData[0]?.engagementCheckInRating;

    if (result === 1) {
      setCheckinRating(150);
      setCheckinValue("Red");
    } else if (result === 2) {
      setCheckinRating(450);
      setCheckinValue("Yellow");
    } else if (result === 3) {
      setCheckinRating(750);
      setCheckinValue("Green");
    } else {
      setCheckinRating(null);
      setCheckinValue("");
    }
  }, [clientRatingData]);

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],
        needleValue: Number(checkInRating),
        // Change This for Meter
        backgroundColor: ["red", "#FFCE56", "#71B278"],
        hoverBackgroundColor: ["red", "#FFCE56", "#71B278"],
      },
    ],
    options: {
      layout: {},
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - 200;
      var cx = cw / 2;
      var cy = ch + 95;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });

  useEffect(() => {
    dispatch(getToogleData());
  }, []);

  const closeDescriptionUpdateModel = () => {
    setShowUpdateDescription(false);
  };

  const closeEngagementUpdateModel = () => {
    setShowUpdateEngagementNotes(false);
  };

  const showUpdateDescriptionModel = () => {
    setShowUpdateDescription(!showUpdateDescription);
  };

  const showUpdateEngagementNotesModel = () => {
    setShowUpdateEngagementNotes(!showUpdateEngagementNotes);
  };

  const isClientApproved =
    clientRatingData?.[0]?.ratingData?.[0]?.isClientApproved;

  return (
    <Layout
      isClient={true}
      clientApproved={
        clientRatingData.length > 0 &&
        clientRatingData[0]?.ratingData[0]?.isClientApproved
      }
      mentorSubmitted={
        clientRatingData.length > 0 &&
        clientRatingData[0]?.ratingData[0]?.isMentorSubmitted
      }
    >
      <Grid container mt={2} spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: "dinBold,sans-serif",
              textAlign: { xs: "center", md: "left" }, // Centered on small screens, left on large
            }}
            gutterBottom
            variant="h9"
            component="div"
          >
            Impact Updates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2.25} sx={{ display: "flex", alignItems: "center" }}>
          <TextField sx={{ width: "100%" }}
            type="date"
            name="startDate"
            id="demo-simple-select"
            required={true}
            size="small"
            value={startDate}
            defaultValue=""
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={0.5} md={0.5}
          sx={{display: "flex",flexDirection: "row",justifyContent: "center",alignItems: "center"}}>
          <Typography sx={{ fontSize: "1rem" }} variant="h9" component="div">
            To
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} md={2.25} sx={{ display: "flex", alignItems: "center" }}>
          <TextField sx={{ width: "100%" }}
            type="date"
            name="startDate"
            value={endDate}
            size="small"
            id="demo-simple-select"
            required={true}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={5.5} md={3}
          sx={{display: "flex",alignItems: "center",justifyContent: "center"}}>
          {clientRatingData.length > 0 &&
            clientRatingData[0].ratings.length > 0 && (
              <FormSubmitButton
                sx={{ textTransform: "none", justifyContent: "center" }}
                type="submit"
                variant="contained"
                size="medium"
                onClick={handleShowPreviousOutcomes}
              >
                See Previous Updates
              </FormSubmitButton>
            )}
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: { xs: 0.5, sm: 0.5, md: 1.5 }, mb: 2 }}>
        <Grid item xs={12} md={12}>
          <Typography sx={{fontSize: "1.35rem",fontWeight: "400",fontFamily: "sans-serif",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Engagement Start Date : {newstartDate}
          </Typography>
        </Grid>
      </Grid>

      <HowToUse
        HowToUseContent={HowToUseContent}
        HowToUseDescription={HowToUseDescription}
        HowToUseDescription1={HowToUseDescription1}
      />

      <Grid mt={2} container>
        <Grid container spacing={2}>
          <Grid sx={{ width: "100%" }} item xs={12} sm={12} md={6}>
            <Stack
              sx={{display: "flex",flexDirection: "row",justifyContent: "center",marginTop: "2rem",
                fontFamily: "arial",
              }}
            >
              <PageHeader variant="pageSecHeader">
                {" "}
                Level Of Engagement
              </PageHeader>

              <Stack sx={{ width: "10rem", marginLeft: "1rem" }}>
                <FormControl size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={isClientApproved}
                    onChange={handleCheckinChange}
                    value={checkinValue}
                    size="small"
                  >
                    <MenuItem key={1} value="Red">
                      {" "}
                      Red{" "}
                    </MenuItem>
                    <MenuItem key={2} value="Yellow">
                      {" "}
                      Yellow{" "}
                    </MenuItem>
                    <MenuItem key={3} value="Green">
                      {" "}
                      Green{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            <Stack>
              <SpeedoMeter
                title="Cycle Time"
                leftColor="#90EE90"
                middleColor="#E6E600"
                rightColor="#FF7F7F"
                hoverLeftColor="#33FF33"
                hoverMiddleColor="#FFFF19"
                hoverRightColor="red"
                needleValue={checkInRating}
                needlePosition={78}
                needleHeight={30}
                fixHeight={180}
                sx={{
                  width: "350px",
                  height: "330px",
                  marginTop: "-4.5rem",
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Stack sx={{display: "flex",flexDirection: "row",justifyContent: "center",marginTop: "3rem"}}>
              <PageHeader variant="pageSecHeader">Engagement Notes</PageHeader>
            </Stack>

            <Stack sx={{}}>
              <MuiDataGridForSummary
                setShowDelete={false}
                select={(data) => dispatch(rowSelect(data))}
                setShowEdit={
                  !isClientApproved && showUpdateEngagementNotesModel
                }
                data={dataThree}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {clientToggleData?.length > 0 &&
            clientToggleData[0]?.dateOfLastMeeting && (
              <Grid item xs={12} sm={12} md={4}>
                <span style={{fontSize: "1.125rem",fontWeight: "bold",display: "block"}}>
                  Date of Last Meeting:
                </span>
                <TextField sx={{ width: "100%" }}
                  type="date"
                  size="small"
                  name="lastmeeting"
                  id="outcome-start-date"
                  value={dateObj?.lastmeeting}
                  disabled
                />
              </Grid>
            )}

          {clientToggleData?.length > 0 &&
            clientToggleData[0]?.dateOfLastManagerTouchpoint && (
              <Grid item xs={12} sm={12} md={4}>
                <span style={{fontSize: "1.125rem",fontWeight: "bold",display: "block"}}>
                Date of Last Manager Touch Point:
                </span>
                <TextField sx={{ width: "100%" }}
                  type="date"
                  size="small"
                  name="managerTouchPoint"
                  id="outcome-start-date"
                  value={dateObj?.managerTouchPoint}
                  disabled
                />
              </Grid>
            )}

          {clientToggleData?.length > 0 &&
            clientToggleData[0]?.dateOfLastHrTouchpoint && (
              <Grid item xs={12} sm={12} md={4}>
                <span style={{fontSize: "1.125rem",fontWeight: "bold",display: "block"}}>
                Date of Last HR Touch Point:
                </span>
                <TextField sx={{ width: "100%" }}
                  type="date"
                  size="small"
                  name="hrPartnerTouchPoint"
                  id="outcome-start-date"
                  value={dateObj?.hrPartnerTouchPoint}
                  disabled
                />
              </Grid>
            )}
        </Grid>

        <Formik
          enableReinitialize={true}
          initialValues={{
            engagementNote: selectedData?.engagementNote,
          }}
          // validationSchema={validationImprovementSchema}
          onSubmit={(values, { resetForm }) => {
            let data = {
              id: selectedData?.id,
              engagementNote: values?.engagementNote,
            };
            dispatch(updateEngagementNotes(data)).then((res) => {
              if (res?.payload?.data?.ok) {
                setTimeout(() => {
                  dispatch(resetMessage());
                  closeEngagementUpdateModel();
                  dispatch(getLatestOutcomesForClient());
                  // resetForm({values:""})
                }, 2000);
              }
              if (res?.payload?.error) {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                  // closeImprovementModel();
                  // resetForm({values:""})
                }, 3000);
              }
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
            resetForm,
          }) => (
            <>
              {showUpdateEngagementNotes && (
                <CommonCurdPopUp
                  heading={`Update Engagement Notes`}
                  mediumScreen={true}
                  // close={closeDescriptionUpdateModel}
                  close={() => {
                    closeEngagementUpdateModel();
                    resetForm(); // Reset form when closing the dialog box
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

                    <Grid item xs={12} sm={12} md={11}>
                      <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
                          {values?.engagementNote?.length
                            ? 500 - values?.engagementNote?.length
                            : 500}{" "}
                          Characters remaining
                        </Stack>
                        <TextField sx={{ width: "100%", height: "5rem" }}
                          label="Engagement Notes"
                          type="text"
                          name="engagementNote"
                          required={true}
                          margin="normal"
                          variant="filled"
                          id="engagementNote"
                          value={values.engagementNote}
                          onChange={handleChange("engagementNote")}
                          error={
                            touched.engagementNote &&
                            (Boolean(errors.engagementNote) ||
                              values.engagementNote === "")
                          }
                          // helperText={
                          //   touched.engagementNote && errors.engagementNote ? (
                          //     errors.engagementNote
                          //   ) : values.engagementNote === "" ? (
                          //     <FormHelperText
                          //       error={true}
                          //       sx={{ color: "red" }}
                          //     >
                          //       Description is required
                          //     </FormHelperText>
                          //   ) : values?.engagementNote?.length > 500 ? (
                          //     <FormHelperText
                          //       error={true}
                          //       sx={{ color: "red" }}
                          //     >
                          //       Description should have less than 500
                          //       characters
                          //     </FormHelperText>
                          //   ) : null
                          // }
                          helperText={
                            touched.engagementNote && errors.engagementNote ? (
                              errors.engagementNote
                            ) : values?.engagementNote?.length > 500 ? (
                              <FormHelperText
                                error={true}
                                // sx={{ color: "red" }}
                              >
                                Engagement Notes should have less than 500
                                characters
                              </FormHelperText>
                            ) : null
                          }
                        />
                      </Stack>
                    </Grid>

                    <div
                      style={{display: "flex",flexDirection: "row",justifyContent: "center",alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <LoadingButton
                        onClick={handleSubmit}
                        loadingIndicator={<CircularProgress size={20} />}
                        loading={loading}
                        type="submit"
                        variant="contained"
                        // className={classes.btnSubmit}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </Grid>
                </CommonCurdPopUp>
              )}
            </>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={{
            description: selectedData?.progressDescription,
          }}
          // validationSchema={validationImprovementSchema}
          onSubmit={(values, { resetForm }) => {
            let data = {
              id: selectedData?.id,
              progressDescription: values?.description,
            };
            dispatch(updateImpactUpdatesDescription(data)).then((res) => {
              if (res?.payload?.data?.ok) {
                setTimeout(() => {
                  dispatch(resetMessage());
                  closeDescriptionUpdateModel();
                  dispatch(getLatestOutcomesForClient());
                  // resetForm({values:""})
                }, 2000);
              }
              if (res?.payload?.error) {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                  // closeImprovementModel();
                  // resetForm({values:""})
                }, 3000);
              }
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
            resetForm,
          }) => (
            <>
              {showUpdateDescription && (
                <CommonCurdPopUp
                  heading={`Update Additional Commentary`}
                  mediumScreen={true}
                  // close={closeDescriptionUpdateModel}
                  close={() => {
                    closeDescriptionUpdateModel();
                    resetForm(); // Reset form when closing the dialog box
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

                    <Grid item xs={12} sm={12} md={11}>
                      <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
                          {values?.description?.length
                            ? 500 - values?.description?.length
                            : 500}{" "}
                          Characters remaining
                        </Stack>
                        <TextField sx={{ width: "100%", minHeight: "5rem" }}
                          multiline
                          label="Description"
                          type="text"
                          name="description"
                          required={true}
                          margin="normal"
                          variant="filled"
                          id="description"
                          value={values.description}
                          onChange={handleChange("description")}
                          error={
                            touched.description &&
                            (Boolean(errors.description) ||
                              values.description === "")
                          }
                          helperText={
                            touched.description && errors.description ? (
                              errors.description
                            ) : values.description === "" ? (
                              <FormHelperText
                                error={true}
                                sx={{ color: "red" }}
                              >
                                Description is required
                              </FormHelperText>
                            ) : values?.description?.length > 500 ? (
                              <FormHelperText
                                error={true}
                                sx={{ color: "red" }}
                              >
                                Description should have less than 500 characters
                              </FormHelperText>
                            ) : null
                          }
                        />
                      </Stack>
                    </Grid>

                    <div
                      style={{display: "flex",flexDirection: "row",justifyContent: "center",alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <LoadingButton
                        onClick={handleSubmit}
                        loadingIndicator={<CircularProgress size={20} />}
                        loading={loading}
                        type="submit"
                        variant="contained"
                        // className={classes.btnSubmit}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </Grid>
                </CommonCurdPopUp>
              )}
            </>
          )}
        </Formik>

        <PopUpModel
          open={showModelGenerate}
          handleClose={closeShowModelGenerate}
          closeModel={closeShowModelGenerate}
        >
          <Box
            sx={{ ...style, width: 700 }}
            component="form"
            className={classes.responsive_width}
            noValidate
          >
            <ModalCloseBtn close={closeShowModelGenerate} />

            <div className={classes.previousSummaryClass}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                <Select
                  type="select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="generateDateValue"
                  value={generateDateValue}
                  label="Geography"
                  onChange={handleDateGenerateChange}
                >
                  {previousOutcomeData.length > 0 &&
                    previousOutcomeData.map((itemValue) => {
                      return (
                        <MenuItem key={itemValue.id} value={itemValue.id}>
                          {format(
                            new Date(itemValue.ratingFromDate),
                            "MM-dd-yyyy"
                          )}{" "}
                          to{" "}
                          {format(
                            new Date(itemValue.ratingToDate),
                            "MM-dd-yyyy"
                          )}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>

            <Stack sx={{ marginTop: "1rem" }}>
              <FormSubmitButton
                type="submit"
                variant="contained"
                onClick={handleGenerateDateSubmit}
              >
                Submit
              </FormSubmitButton>
            </Stack>
          </Box>
        </PopUpModel>
      </Grid>
      {/* </Stack> */}
      {/* </Stack> */}
      {/* {clientRatingData.length > 0 &&
        clientRatingData[0].ratings.length > 0 && ( */}
      <Stack
        sx={{
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "space-between",
          mt: "1rem",
        }}
      >
        <Grid container>
          <Grid md={6}>
            <Stack sx={{display: "flex",flexDirection: "column",fontFamily: "arial"}}>
              <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Engagement Key
              </Typography>
              <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Green
                </span>{" "}
                Highly engaged with coach/set meetings regulary/leaning in.
              </Typography>
              <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Yellow
                </span>{" "}
                Engaged when meeting but challenging to schedule with.
              </Typography>
              <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Red
                </span>{" "}
                Goes long period without meeting with coach/not engaged.
              </Typography>
            </Stack>
          </Grid>

          {clientToggleData?.length > 0 &&
            clientToggleData[0]?.progressRatingScale && (
              <Grid container item md={6}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography sx={{fontSize: "1.125rem",fontWeight: "bold",fontWeight: "bold"}}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    Progress Scale Rating
                  </Typography>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      0
                    </span>{" "}
                    Baseline - No progress
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      2
                    </span>{" "}
                    Significant Improvement
                  </Typography>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      1
                    </span>{" "}
                    Work in Progress
                  </Typography>
                </Grid>

                <Grid item md={8} sm={12} xs={12}>
                <Typography sx={{ fontSize: "0.90rem" }} gutterBottom variant="h9" component="div">
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      3
                    </span>{" "}
                    Completed or Maintaining Successful Execution
                  </Typography>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Stack>
      {/* )} */}
      {clientRatingData?.length > 0 && (
        <Stack sx={{ mt: "1rem" }}>
          <Grid container>
            <Grid md={6}>
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{fontSize: "1.125rem",fontWeight: "bold",marginTop: "0.5rem"}}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Focus Areas
                </Typography>
              </Stack>
            </Grid>

            <Grid md="5">
              <Stack sx={{display: "flex",flexDirection: "row",justifyContent: "flex-end"}}>
                <Typography sx={{fontSize: "1.125rem",fontWeight: "bold",marginTop: "0.5rem",whiteSpace: "nowrap"}}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Progress Updates
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      {commentArr?.length > 0 && !commentEdit
        ? commentArr[0]?.ratings?.length > 0 &&
          commentArr[0]?.ratings?.map((data, index) => {
            return ( 
              <Stack sx={{display: "flex",flexDirection: "row",fontFamily: "arial",marginTop: "2rem"}}
                key={data.id}
                >
                <Grid container>
                  <Grid item xs={9} sm={9} md={2}>
                    <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                      className={classes.ratingParentOne}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      {data?.otherDevelopmentAreaCoachingFocus}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Stack sx={{marginLeft: "10rem",display: "flex",width: "80%",flexDirection: "column",fontFamily: "arial"}}
                      className={classes.ratingParentTwo}
                      >
                      <TextField
                        multiline={true}
                        fullWidth={true}
                        placeholder="Please Enter Feedback *"
                        sx={{
                          width: "100%",
                          minHeight: "5rem",
                          marginLeft: "3rem",
                        }}
                        hiddenLabel
                        variant="filled"
                        defaultValue={data?.comment}
                        onChange={(e) => handleCommentChange(e, data.id)}
                        // onChange={(e)=>setRatingComments(e.target.value)}
                      />

                      <div
                        style={{
                          width: "100%",
                          "& .super-app-theme": {
                            "&:nth-of-type(odd)": {
                              bgcolor: (theme) => theme.palette.primary.light,
                            },
                          },
                        }}
                      >
                        {clientToggleData?.length > 0 &&
                          clientToggleData[0]?.progressRatingScale && (
                            <Stack sx={{display: "flex",flexDirection: "row",marginTop: "1rem",width: "100%",
                                marginLeft: "3rem",
                                // paddingBottom: "1rem",
                              }}
                            >
                              <Typography sx={{ fontSize: "1rem", color: "blue" }}
                                gutterBottom
                                component="div"
                              >
                                Rating
                              </Typography>
                              <Slider
                                // disabled
                                sx={{marginLeft: "1rem",width: "100%"}}
                                aria-label="Custom marks"
                                value={data?.rating}
                                onChange={(e) => handleSliderChange(e, data.id)}
                                getAriaValueText={valuetext}
                                step={50}
                                max={150}
                                marks={marks}
                                disabled={
                                  clientRatingData?.length > 0 &&
                                  clientRatingData[0]?.ratingData?.length > 0 &&
                                  clientRatingData[0]?.ratingData[0]
                                    ?.isClientApproved
                                    ? "disabled"
                                    : null
                                }
                              />
                            </Stack>
                          )}
                        {clientRatingData?.length > 0 &&
                          clientRatingData[0]?.ratingData?.length > 0 &&
                          !clientRatingData[0]?.ratingData[0]
                            ?.isClientApproved && (
                            <Stack
                              sx={{display: "flex",flexDirection: "row",justifyContent: "flex-start",alignItems: "center",
                                fontFamily: "arial",
                                marginTop: "1rem",
                                marginLeft: "3rem",
                              }}
                            >
                              <FormSubmitButton
                                disabled={
                                  !data.hasOwnProperty("disabled")
                                    ? "disabled"
                                    : data?.disabled && data?.disabled
                                }
                                sx={{ justifyContent: "Center" }}
                                type="submit"
                                variant="contained"
                                onClick={() =>
                                  handleUpdateMentorRating(
                                    data.id,
                                    data.comment,
                                    data?.rating
                                  )
                                }
                                //   className={classes.btnSubmit}
                                //   onClick={handleSubmit}
                              >
                                Save
                              </FormSubmitButton>
                            </Stack>
                          )}
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            );
          })
        : commentArr?.length > 0 &&
          commentArr?.map((data) => {
            return (
              <Stack
                key={data.id}
                className={classes.ratingParentClass}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "arial",
                  marginTop: "2rem",
                }}
              >
                <Grid container>
                  <Grid item xs={9} sm={9} md={2}>
                    <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                      className={classes.ratingParentOne}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      {data?.otherDevelopmentAreaCoachingFocus}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Stack
                      className={classes.ratingParentTwo}
                      sx={{marginLeft: "10rem",display: "flex",width: "80%",flexDirection: "column",fontFamily: "arial"}}>
                      <TextField
                        multiline={true}
                        fullWidth={true}
                        placeholder="Please Enter Feedback *"
                        sx={{
                          width: "100%",
                          minHeight: "5rem",
                          marginLeft: "3rem",
                        }}
                        hiddenLabel
                        variant="filled"
                        value={data?.comment}
                        onChange={(e) => handleCommentChange(e, data.id)}
                        // helperText={
                        //   data?.comment &&
                        //   data.comment.length > 500 && (
                        //     <FormHelperText error={true} sx={{ color: "red" }}>
                        //       Description should have less than 500 characters
                        //     </FormHelperText>
                        //   )}
                        helperText={
                          !data?.comment || data.comment.trim() === "" ? (
                            <FormHelperText error={true} sx={{ color: "red" }}>
                              Description is required
                            </FormHelperText>
                          ) : data.comment.trim().length > 750 ? (
                            <FormHelperText error={true} sx={{ color: "red" }}>
                              Description should have less than 750 characters
                            </FormHelperText>
                          ) : null
                        }
                        // onChange={(e)=>setRatingComments(e.target.value)}
                      />
                      <div
                        style={{
                          width: "100%",
                          "& .super-app-theme": {
                            "&:nth-of-type(odd)": {
                              bgcolor: (theme) => theme.palette.primary.light,
                            },
                          },
                        }}
                      >
                        {clientToggleData?.length > 0 &&
                          clientToggleData[0]?.progressRatingScale && (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "1rem",
                                width: "100%",
                                marginLeft: "3rem",
                                // paddingBottom: "1rem",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "1rem", color: "blue" }}
                                gutterBottom
                                component="div"
                              >
                                Rating
                              </Typography>
                              <Slider
                                // disabled
                                sx={{
                                  marginLeft: "1rem",
                                  width: "100%",
                                }}
                                aria-label="Custom marks"
                                value={data?.rating}
                                onChange={(e) => handleSliderChange(e, data.id)}
                                getAriaValueText={valuetext}
                                step={50}
                                max={150}
                                marks={marks}
                                disabled={
                                  clientRatingData?.length > 0 &&
                                  clientRatingData[0]?.ratingData?.length > 0 &&
                                  clientRatingData[0]?.ratingData[0]
                                    ?.isClientApproved
                                    ? "disabled"
                                    : null
                                }
                              />
                            </Stack>
                          )}
                        {clientRatingData?.length > 0 &&
                          clientRatingData[0]?.ratingData?.length > 0 &&
                          !clientRatingData[0]?.ratingData[0]
                            ?.isClientApproved && (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontFamily: "arial",
                                marginTop: "1rem",
                                marginLeft: "3rem",
                              }}
                            >
                              <FormSubmitButton
                                disabled={
                                  !data.hasOwnProperty("disabled")
                                    ? "disabled"
                                    : data?.disabled && data?.disabled
                                }
                                sx={{ justifyContent: "Center" }}
                                type="submit"
                                variant="contained"
                                id="save"
                                onClick={() =>
                                  handleUpdateMentorRating(
                                    data.id,
                                    data.comment,
                                    data?.rating
                                  )
                                }
                                //   className={classes.btnSubmit}
                                //   onClick={handleSubmit}
                              >
                                Save
                              </FormSubmitButton>
                            </Stack>
                          )}
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            );
          })}
      <Grid item xs={12} sm={12} md={12}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <PageHeader variant="pageSecHeader">Additional Commentary</PageHeader>

          {/* <Stack sx={{ marginLeft: "1rem" }}>
                    <EyeInfo eyeText="Please provide commentary on why you rated your client at the level of engagement noted. For example – Jane is highly engaged in our mentoring relationship. We have a regular cadence of meetings scheduled…" />
                  </Stack> */}
        </Stack>

        <Stack sx={{}}>
          <MuiDataGridForSummary
            setShowEdit={!isClientApproved && showUpdateDescriptionModel}
            select={(data) => dispatch(rowSelect(data))}
            viewAdd={true}
            data={dataTwo}
            setShowDelete={false}
          />
        </Stack>
      </Grid>

      {clientRatingData.length > 0 &&
        clientRatingData[0].ratings.length > 0 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <AcceptOutcome />

            <SubmitByHr />
            <SubmitByManager />
          </Stack>
        )}

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Assesment;
