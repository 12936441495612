import React, { useState, useEffect } from "react";
// components
import Layout from "../../layout/Layout";
import { Formik } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { PageHeader } from "@excoleadershipui/pionweblib";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import {
  GridCellExpand,
  MuiDataGrid,
  PopUpModel,
  ModalCloseBtn,
} from "../../components";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import { getToggleUserData } from "../../features/auth/authSlice";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import Notifier from "../../components/common/Notifier";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import {resetMessage,resetErrMessage} from "../../features/chr/coaching_focus/ChrFocusSlice";
import { Grid } from "@mui/material";
import { format } from "date-fns";

import HowToUse from "../../components/common/HowToUse";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-between",
  alignItems: "flex-start",
};

const Assesment = (props) => {
  const [accountName, setAccountName] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const {
    leadershipStrengthData,
    leadershipImprovementData,
    clientFocusPurposeCHRData,
    clientFocusData,
    clientCHRIdData,
  } = useSelector((state) => ({ ...state.CHrCoachingFocus }));

  const {
    privateFeedbackDataByChr,
    privateFeedbackDataByHr,
    selectedData,
    privateFeedbackDataByManager,
    message,
    errMessage,
    loading,
  } = useSelector((state) => ({
    ...state.privateFeedback,
  }));

  const dispatch = useDispatch();

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const validationFeedbackSchema = Yup.object({
    userMessage: Yup.string()
      .test("is-not-blank", "Message cannot be blank", (value) => {
        return value && value.trim().length > 0;
      })
      .required("Message is required"),
  });

  const showAddModel = () => {
    setShowAdd(!showAdd);
    // dispatch(coachingFocusListEmpty())
  };

  const showUpdateModel = () => {
    setShowUpdate(!showUpdate);
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeletePrivateFeedback = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(props?.deleteFeedback(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(props?.getAllFeedback(clientName?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
        }, 3000);
      }
    });
  };

  const closeModel = () => {
    setShowAdd(false);
  };
  const closeUpdate = () => {
    setShowUpdate(false);
  };

  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };

  const getLastResponedDate = (params) => {
    if (!params?.row?.response) {
      return ""; // Return empty if response is missing
    }
    return params.row.lastResponded
      ? format(new Date(params.row.lastResponded), "MM-dd-yyyy")
      : "";
  };

  const data = {
    rows:
      (privateFeedbackDataByHr?.length > 0 && privateFeedbackDataByHr) ||
      (privateFeedbackDataByManager?.length > 0 &&
        privateFeedbackDataByManager) ||
      (privateFeedbackDataByChr?.length > 0 && privateFeedbackDataByChr),
    columns: [
      {
        field: "content",
        headerName: "Your Message to Mentor",
        width: 410,

        // renderCell: renderCellExpand,
        // renderCell: (params) => <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>{params?.row?.content}</div>
        renderCell: (params) => (
          <div style={{ padding: "1rem 0" }}>
            {params?.row?.content?.split("\n").map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        )
      },

      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 180,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        // hide: toggleMentorData?.length > 0 &&
        //   toggleMentorData[0]?.anticipatedStartDate ? false : true
      },

      {
        field: "response",
        headerName: "Mentor Response",
        width: 410,
        // valueGetter: mentorGetCoachingValue,
        // renderCell: renderCellExpand,
        renderCell: (params) => <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>{params?.row?.response}</div>
      },

      {
        field: "dateAddedTwo",
        headerName: "Date Added",
        width: 170,
        renderCell: renderCellExpand,
        valueGetter: getLastResponedDate,
      },
    ],
  };

  const HowToUseContent = `
Please utilize the table below to leave private feedback for the leader's Mentor regarding the ongoing engagement.
Your feedback and any direct response that the Mentor provides will ONLY be visible to you and the Mentor.
The leader (mentee) and other stakeholders (including The ExCo Group team) will not have access or visibility 
into the messages recorded here.
`;

  useEffect(() => {
    dispatch(props?.getAllFeedback(clientName?.id));
  }, []);

  return (
    <Layout>
      <Formik
        initialValues={{
          userMessage: "",
        }}
        validationSchema={validationFeedbackSchema}
        onSubmit={(values, { resetForm }) => {
          let data = {
            excoCustomerUserId: clientName?.id,
            content: values?.userMessage,
            excoMentorId: clientName?.mentorId,
          };

          dispatch(props?.createFeedback(data)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                closeModel();
                dispatch(props?.getAllFeedback(clientName?.id));
                resetForm({ values: "" });
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
                // resetForm({ values: "" });
              }, 2000);
            }
          });
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          resetForm,
          setFieldValue,
        }) => (
          <PopUpModel
            open={showAdd}
            handleClose={() => {
              closeModel();
              resetForm({
                values: "",
              });
            }}
            closeModel={() => {
              closeModel();
              resetForm({
                values: "",
              });
            }}
          >
            <Box
              sx={{ ...style, width: 700 }}
              // className={classes.responsive_width}
              component="form"
              noValidate
              onSubmit={handleSubmit}
            >
              <ModalCloseBtn
                close={() => {
                  closeModel();
                  resetForm({
                    values: "",
                  });
                }}
              />

              <Stack>
                <Typography
                  sx={{ fontSize: "1.125rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Leave Message for {clientName?.mentorName}
                </Typography>

                {/* <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Your Message :
                  </span>{" "}
                 Client has to add more star players to their team.
                </Typography> */}

                <Typography sx={{ fontSize: "0.90rem" }} variant="h9" component="div">
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Your Message : <span style={{ color: "red" }}>*</span>
                  </span>{" "}
                </Typography>
              </Stack>

              <Stack style={{ width: "100%" }}>
                {/* <Stack
                    sx={{
                      fontSize: "0.8rem",
                      alignSelf: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    {" "}
                    {values?.userMessage?.length
                      ? 500 - values?.userMessage?.length
                      : 500}{" "}
                    Characters remaining
                  </Stack> */}
                <TextField
                  //  InputProps={{
                  //    endAdornment: (
                  //      <InputAdornment
                  //        sx={{ paddingBottom: "2rem", fontSize: "0.8rem" }}
                  //      >
                  //        {500 - values?.userMessage?.length} Characters remaining
                  //      </InputAdornment>
                  //    ),
                  //  }}
                  sx={{ width: "100%" }}
                  // label="Your Response"
                  type="text"
                  multiline={true}
                  rows={5}
                  name="userMessage"
                  size="large"
                  // required={true}
                  margin="normal"
                  variant="filled"
                  id="userMessage"
                  value={values.userMessage}
                  onChange={handleChange("userMessage")}
                  error={touched.userMessage && Boolean(errors.userMessage)}
                  helperText={
                    (touched.userMessage && errors.userMessage) ||
                    (values?.userMessage?.length > 500 && (
                      <FormHelperText error={true} sx={{ color: "red" }}>
                        Message should have less than 500 characters
                      </FormHelperText>
                    ))
                  }
                />
              </Stack>
              <Stack sx={{ alignSelf: "flex-end" }}>
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  sx={{ textTransform: "none" }}
                  loading={loading}
                  type="submit"
                  variant="contained"
                //   className={classes.btnSubmit}
                >
                  Submit Message
                </LoadingButton>

                {/* <Button
                  type="submit"
                  variant="contained"
                 sx={{textTransform:"none"}}
                //   className={classes.btnSubmit}
                >
                  Submit Message
                </Button> */}
              </Stack>
            </Box>
          </PopUpModel>
        )}
      </Formik>

      <Formik
        enableReinitialize
        initialValues={{
          userMessage: selectedData?.content,
        }}
        validationSchema={validationFeedbackSchema}
        onSubmit={(values, { resetForm }) => {
          let data = {
            id: selectedData?.id,
            // excoCustomerUserId: clientName?.id,
            content: values?.userMessage,
            // excoMentorId: clientName?.mentorId
          };

          dispatch(props?.updateFeedback(data)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                closeUpdate();
                dispatch(props?.getAllFeedback(clientName?.id));
                resetForm({ values: "" });
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
                // resetForm({ values: "" });
              }, 2000);
            }
          });
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          resetForm,
          setFieldValue,
        }) => (
          <PopUpModel
            open={showUpdate}
            handleClose={() => {
              closeUpdate();
              resetForm({ values: "" });
            }}
            closeModel={() => {
              closeUpdate();
              resetForm({ values: "" });
            }}
          >
            <Box
              sx={{ ...style, width: 700 }}
              // className={classes.responsive_width}
              component="form"
              noValidate
              onSubmit={handleSubmit}
            >
              <ModalCloseBtn
                close={() => {
                  closeUpdate();
                  resetForm({
                    values: "",
                  });
                }}
              />

              <Stack>
                <Typography sx={{ fontSize: "1.125rem" }} gutterBottom variant="h9" component="div">
                  Leave Message for {clientName?.mentorName}
                </Typography>

                {/* <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Your Message :
                  </span>{" "}
                 Client has to add more star players to their team.
                </Typography> */}

                <Typography sx={{ fontSize: "0.90rem" }} variant="h9" component="div">
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Your Message : <span style={{ color: "red" }}>*</span>
                  </span>{" "}
                </Typography>
              </Stack>

              <Stack style={{ width: "100%" }}>
                {/* <Stack
                    sx={{
                      fontSize: "0.8rem",
                      alignSelf: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    {" "}
                    {values?.userMessage?.length
                      ? 500 - values?.userMessage?.length
                      : 500}{" "}
                    Characters remaining
                  </Stack> */}
                <TextField
                  //  InputProps={{
                  //    endAdornment: (
                  //      <InputAdornment
                  //        sx={{ paddingBottom: "2rem", fontSize: "0.8rem" }}
                  //      >
                  //        {500 - values?.userMessage?.length} Characters remaining
                  //      </InputAdornment>
                  //    ),
                  //  }}
                  sx={{ width: "100%" }}
                  // label="Your Response"
                  type="text"
                  multiline={true}
                  rows={5}
                  name="userMessage"
                  size="large"
                  required={true}
                  margin="normal"
                  variant="filled"
                  id="userMessage"
                  defaultValue={selectedData?.content}
                  value={values.userMessage}
                  onChange={handleChange("userMessage")}
                  error={touched.userMessage && Boolean(errors.userMessage)}
                  helperText={
                    (touched.userMessage && errors.userMessage) ||
                    (values?.userMessage?.length > 500 && (
                      <FormHelperText error={true} sx={{ color: "red" }}>
                        Message should have less than 500 characters
                      </FormHelperText>
                    ))
                  }
                />
              </Stack>
              <Stack sx={{ alignSelf: "flex-end" }}>
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  sx={{ textTransform: "none" }}
                  loading={loading}
                  type="submit"
                  variant="contained"
                //   className={classes.btnSubmit}
                >
                  Submit Message
                </LoadingButton>
                {/* <Button
                  type="submit"
                  variant="contained"
                  sx={{textTransform:"none"}}
                //   className={classes.btnSubmit}
                >
                  Submit Message
                </Button> */}
              </Stack>
            </Box>
          </PopUpModel>
        )}
      </Formik>

      <Stack sx={{marginTop: "1rem",display: "flex",justifyContent: "start",alignItems: "center",flexDirection: "row"}}>
        <PageHeader variant="pageHeader">
          Private Feedback to Mentor for {clientName?.firstName}{" "}
          {clientName?.lastName}
        </PageHeader>
        {/* <Stack sx={{ marginLeft: "1rem" }}>
          <EyeInfo
            eyeText="Please utilize the table below to leave private feedback for the leader’s Mentor regarding the ongoing engagement. Your feedback and any direct response that the Mentor provides will ONLY be visible to you and the Mentor. Neither the leader (mentee) nor any other stakeholders (including the ExCo account management team) will have access or visibility into the messages recorded below."
            isShow={true}
          />
        </Stack> */}

        {/* <Grid item xs={11} sm={11} md={6}>
            <Stack sx={{width:"13rem",marginLeft:"1rem"}}>
            <MuiAutocomplete
              name="clientName"
              options={clientFocusData .length> 0 ? clientFocusData:[] }
              setValue={(name, newValue) => setClientName(newValue)}
              value={clientName}
            
              innerLevel={true}
              
          />
            </Stack>
            </Grid> */}
      </Stack>

        <HowToUse
          HowToUseContent={HowToUseContent}
        />

      {clientName && (
        <Stack>
          <Stack
            sx={{mt: 2,mb: 2,display: "flex",justifyContent: "center",alignItems: "center",flexDirection: "row",
              fontFamily: ["dinBold", "sans-serif"],
            }}
          >
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "700" }} component="div">
          {" "}
              Your Feedback to {clientName?.mentorName}
            </Typography>
          </Stack>

          <Stack >
            <MuiDataGrid
              isRowHeight={true}
              setShowDelete={() =>
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this record?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => handleDeletePrivateFeedback(),
                })
              }
              setShowEdit={showUpdateModel}
              viewAdd={true}
              data={data}
              select={(data) => dispatch(props?.rowSelect(data))}
              setShowAdd={showAddModel}
            />
          </Stack>
        </Stack>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Assesment;
