import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import EngagementDetailsClient from "../features/dashboard/dashBoardSlice";
import ClientAssesment from "../features/assesment/assesmentSlice";
import ClientReports from "../features/reports/reportsSlice";

import HrReports from "../features/hr/reports/reportsSlice";
import HRNomination from "../features/hr/nomination/nominationSlice";
import hrDashboard from "../features/hr/dashboard/dashBoardSlice";
import hrEngagement from "../features/hr/engagement/engagementSlice";
import HrRating from "../features/hr/ratings/ratingSlice";
import engagementHrReports from "../features/hr/reports/reportsSlice";
import HrClientLeadership from "../features/hr/assesment/hrAssesmentSlice";
import HrCoachingFocus from "../features/hr/coaching_focus/hrFocusSlice";
import HREngagementDocuments from "../features/hr/dashboard/engagementHrDocumentSlice";

import CHRReports from "../features/chr/reports/reportsSlice";
import CHRNomination from "../features/chr/nomination/nominationSlice";
import CHRDashboard from "../features/chr/dashboard/dashBoardSlice";
import CHREngagement from "../features/chr/engagement/engagementSlice";
import CHRRating from "../features/chr/ratings/ratingSlice";
import engagementCHRReports from "../features/chr/reports/reportsSlice";
import CHRClientLeadership from "../features/chr/assesment/ChrAssesmentSlice";
import CHRCoachingFocus from "../features/chr/coaching_focus/ChrFocusSlice";
import CHREngagementDocuments from "../features/chr/dashboard/engagementChrDocumentSlice";

import ClientFocus from "../features/coaching_focus/focusSlice";

import ManagerLeadership from "../features/manager/assesment/assesmentManagerSlice";
import ManagerCoachingFocus from "../features/manager/coachingFocus/coachingManagerSlice";
import clientRating from "../features/ratings/ratingSlice";

import ManagerRating from "../features/manager/ratings/ratingSlice";
import engagementClientReports from "../features/reports/reportsSlice";
import ManagerEngagementDocuments from "../features/manager/dashboard/engagementManagerDocumentSlice";

import managerDashboard from "../features/manager/dashboard/dashBoardSlice";
import managerSummary from "../features/manager/reports/reportsSlice";
import forgetPass from "../features/auth/forgotPasswordSlice";
import clientDocumentsReducers from "../features/dashboard/engagementDocumentSlice";
import notificationDetails from "../features/notificationSlice";
import privateFeebackReducres from "../features/privateFeedbackSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    clientEngagement: EngagementDetailsClient,
    clientAssesment: ClientAssesment,
    ClientReports: ClientReports,
    ClientFocus: ClientFocus,
    clientDocuments: clientDocumentsReducers,

    HrReports: HrReports,
    HRNomination: HRNomination,
    HrClientLeadership: HrClientLeadership,
    HrCoachingFocus: HrCoachingFocus,
    hrDashboard: hrDashboard,
    hrEngagement: hrEngagement,
    HrRating: HrRating,
    HREngagementDocuments: HREngagementDocuments,

    CHRReports: CHRReports,
    CHRNomination: CHRNomination,
    CHrClientLeadership: CHRClientLeadership,
    CHrCoachingFocus: CHRCoachingFocus,
    CHRDashboard: CHRDashboard,
    CHREngagement: CHREngagement,
    CHRRating: CHRRating,
    CHREngagementDocuments: CHREngagementDocuments,
    engagementCHRReports: engagementCHRReports,

    ManagerLeadership: ManagerLeadership,
    ManagerEngagementDocuments: ManagerEngagementDocuments,
    ManagerCoachingFocus: ManagerCoachingFocus,
    clientRating: clientRating,

    ManagerRating: ManagerRating,
    managerSummary: managerSummary,

    engagementClientReports: engagementClientReports,

    engagementHrSummary: engagementHrReports,

    managerDashboard: managerDashboard,
    notification: notificationDetails,

    privateFeedback: privateFeebackReducres,
    forgetPass: forgetPass,
  },
});
