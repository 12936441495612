import { useFormik } from "formik";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import * as Yup from "yup";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  IconButton,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { PopUpModel, MuiAutocomplete, FormSubmitButton, CommonCurdPopUp } from "../..";
import { createEngagementDocuments, getEngagementDocuments, resetErrMessage, resetMessage } from '../../../features/dashboard/engagementDocumentSlice';
import { CoPresentOutlined } from "@mui/icons-material";
import { UploadedDocumentTypes } from "../documentTypes";
// import { fileUploadPlan, getMentorCoachingDocumentsByClient,resetErrMessage,resetMessage } from "../../features/coaching_focus/coachingSlice";

// import {
//   textChecker,
// } from "../../../../common/form_validation/regularExpressions";
// import {
//   textFieldErr,
// } from "../../../../common/form_validation/validationErrMessages";
// import { timeoutCall } from "../../../../common/timeoutCall/timeoutCall";
// import CommonErrorMessage from "../../../../common/error-message/CommonErrorMessage";
// import {
//   addRcPolicyAttachments,
//   getRcPolicyAttachments,
// } from "../../../../../../features/project/projectConfig/rc360/rcPolicyAttachmentsSlice";

// import {
//   uploadBacklogAttachments,
//   getBacklogAttachments,
//   deleteBacklogAttachments,
// } from "../../../../../../features/project/requirementsManagement/projectBacklog/backlogAttachmentsSlice";



// -------------------------------------------------------------------------------------------

// import {addleadAttachment, getleadAttachment } from "../../../../../features/crm/leadAttachmentSlice";


// -------------------------------------------------------------------------------------------

const AddAttachments = (props) => {
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const dispatch = useDispatch();

  console.log(props?.typeKey)

  const fileValidationSchema = Yup.array()
    .min(1, "At least one file is required") // Ensure at least one file is selected
    .test("fileFormat", "Invalid format", function (files) {
      if (!files || files.length === 0) return true; // Skip validation if empty (handled by .min(1))

      // const selectedDocumentType = this.parent.documentType;
      const allowedFormats = UploadedDocumentTypes?.find(
        (type) => type.key === props?.selectedDocumentType
      )?.format
        .split(",")
        .map((ext) => ext.trim());

      return files.every((file) => {
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        return allowedFormats?.includes(`.${fileExtension}`);
      });
    })
    .test("fileSize", "One or more files exceed the allowed size", function (files) {
      if (!files || files.length === 0) return true; // Skip validation if empty (handled by .min(1))

      return files.every((file) => {
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        const maxFileSize =
          ["ppt", "pptx", "mp3", "m4a", "mp4"].includes(fileExtension)
            ? 40 * 1024 * 1024 // 40MB
            : 20 * 1024 * 1024; // 20MB

        return file?.size <= maxFileSize;
      });
    });



  const validationSchema = Yup.object({
    attachmentName: Yup.string()
      .required("Attachment Name is required")

      // .matches(textChecker, textFieldErr)
      .test(
        "noEmptySpaces",
        " Field cannot be only empty spaces",
        function (value) {
          return value === undefined || value.trim().length > 0;
        }
      )
      .max(100, "Attachment Name Should Not Exceed 100 Characters"),
    // attachmentType: Yup.string()
    //   // .matches(textChecker, textFieldErr)
    //   .test(
    //     "noEmptySpaces",
    //     " Field cannot be only empty spaces",
    //     function (value) {
    //       return value === undefined || valfue.trim().length > 0;
    //     }
    //   )
    //   .max(50, "Attachment Name Should Not Exceed 50 Characters"),

    fileName: fileValidationSchema,

    // fileName: Yup.mixed()
    //   .required("File Name is required")
    //   .test(
    //     "fileFormat",
    //     "File format is not allowed",
    //     function (value) {
    //       if (!value) return true;


    //       const allowedFormats = UploadedDocumentTypes.find(
    //         (type) => type.key === props?.selectedDocumentType
    //       )?.format
    //         .split(",")
    //         .map((ext) => ext.trim());

    //       const fileExtension = value.name.split(".").pop();
    //       console.log(allowedFormats.includes(`.${fileExtension}`))
    //       return allowedFormats.includes(`.${fileExtension}`);
    //     }
    //   )
    //   .test(
    //     "fileSize",
    //     function (value) {
    //       if (!value) return true;

    //       const fileExtension = value.name.split(".").pop().toLowerCase();
    //       const maxFileSize = fileExtension === "ppt" || fileExtension === "pptx" ||
    //         fileExtension === "mp3" || fileExtension === "m4a" || fileExtension === "mp4"
    //         ? 40 * 1024 * 1024 // 40MB
    //         : 20 * 1024 * 1024; // 20MB

    //       if (value.size > maxFileSize) {
    //         return this.createError({
    //           message: `File size must not exceed ${fileExtension === "ppt" || fileExtension === "pptx" ||
    //             fileExtension === "mp3" || fileExtension === "m4a" || fileExtension === "mp4" ? "40MB" : "20MB"
    //             }`,
    //         });
    //       }

    //       return true;
    //     }
    //   ),
  });

  const formik = useFormik({
    initialValues: {
      attachmentName: props?.attachmentType ? props?.attachmentType : "",
      attachmentType: "",
      fileName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values)
      try {
        const formData = new FormData();
        // formData.append("document", values?.fileName)
        // formData.append("documentName", values?.fileName?.name)

        // formData.append(
        //   "fileType",
        //   values?.attachmentType
        // );
        props?.clientEngagementId && formData.append("clientEngagementId", props?.clientEngagementId);
        props?.nominationId && formData.append("nominationId", props?.nominationId);

        values?.fileName.forEach((file, index) => {
          formData.append(`${props?.typeKey}`, file);
});




        dispatch(props?.createDocument(formData)).then((res) => {
          if (res?.payload?.data?.ok) {
            props.close()
            setTimeout(() => {
              dispatch(props?.resetMessage());
              dispatch(props?.resetErrMessage());

              props?.getDocuments && props.clientEngagementId ?
                dispatch(
                  props?.getDocuments({ clientEngagementId: props.clientEngagementId }
                  )) :
                dispatch(
                  props?.getDocuments({ nominationId: props.nominationId }
                  ))
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(props?.resetErrMessage());
            }, 3000);
          }
        })

          .
          catch((err) => {
            console.log("err ", err);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleFileChange = (event) => {
    const fileName = event.target.files[0];
    formik.setFieldValue("fileName", fileName);
  };
  const handleClear = (event) => {
    formik.setFieldValue("fileName", "");
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = formik.values.fileName.filter((_, i) => i !== index);
    formik.setFieldValue("fileName", updatedFiles);
  };

  // try {
  return (
    <PopUpModel open={props.showAdd}>
      <Card
        className="add-pop-up "
        style={{ marginLeft: "22%", width: "53.25rem" }}
      >
        <CardHeader
          title={
            <h4 style={{ textAlign: "center" }} className="center bold pt-2 header-font-size">
              Add Attachment
            </h4>
          }
          action={
            <IconButton aria-label="cancel" onClick={() => props.close()}>
              <CancelIcon color="red" />
            </IconButton>
          }
          sx={{
            paddingBottom: "0px!important",
          }}
        />
        <CardContent>
          <form noValidate>
            <Grid container>
              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={12} sm={12} md={10}>
                <Stack sx={{ textAlign: "end", fontSize: "0.8rem" }}>Accepted Formats({props?.format})</Stack>
                <TextField
                  disabled
                  label="Attachment Name"
                  type="text"
                  name="attachmentName"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="attachmentName"
                  value={formik.values.attachmentName}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik.touched.attachmentName &&
                    Boolean(formik.errors.attachmentName)
                  }
                  helperText={
                    formik.touched.attachmentName &&
                    formik.errors.attachmentName
                  }
                  sx={{
                    "& .MuiInputLabel-asterisk": {
                      color: "red", // Set the asterisk color to red
                    },
                  }}
                />
                {/* <TextField
                  label="File Type"
                  type="text"
                  name="attachmentType"
                  fullWidth={true}
                  required={false}
                  margin="normal"
                  id="attachmentType"
                  value={formik.values.attachmentType}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik.touched.attachmentType &&
                    Boolean(formik.errors.attachmentType)
                  }
                  helperText={
                    formik.touched.attachmentType &&
                    formik.errors.attachmentType
                  }
                /> */}

              </Grid>

              <Grid container spacing={1} >
                <Grid item xs={12} sm={12} md={1}></Grid>
                {/* Select File Button */}
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <FormGroup className="file-browse-btn-div">
                    <label
                      className="btn label-sm file-browse-btn"
                      style={{ padding: "0.5rem", cursor: "pointer", display: "inline-block" }}
                      onClick={() => document.getElementById("hidden-file-input").click()} // ✅ Triggers file selection
                    >
                      Select File *
                    </label>

                    {/* ✅ Completely Hidden File Input */}
                    <input
                      id="hidden-file-input"
                      type="file"
                      style={{ display: "none" }}
                      multiple={formik.values.allowMultiple} // ✅ Supports single/multiple file selection
                      onChange={(event) => {
                        const newFiles = Array.from(event.target.files);
                        if (newFiles.length === 0) return; // ✅ Prevent empty selections
                        const existingFiles = Array.isArray(formik.values.fileName) ? formik.values.fileName : [];
                        // ✅ Filter out duplicate files (based on name & size)
                        const uniqueFiles = newFiles.filter(
                          (newFile) => !existingFiles.some((existingFile) =>
                            existingFile.name === newFile.name && existingFile.size === newFile.size
                          )
                        );

                        // ✅ Merge unique files for multiple uploads, replace for single upload
                        const updatedFiles = props?.isMultiple
                          ? [...existingFiles, ...uniqueFiles] : [uniqueFiles[0]];
                        formik.setFieldValue("fileName", updatedFiles);

                        event.target.value = ""; // ✅ Allows selecting the same file again
                      }}
                    // disabled={!formik.values.documentType}
                    />

                    {/* ✅ Display Error Below the Button */}
                    {formik.errors.fileName && (
                      <span
                        style={{
                          fontSize: "0.75rem",
                          color: "red",
                          marginTop: "3px",
                          fontFamily: "sans-serif",
                        }}
                      >
                        {Array.isArray(formik.errors.fileName) ? formik.errors.fileName.join(", ") : formik.errors.fileName}
                      </span>
                    )}
                  </FormGroup>
                </Grid>

                {/* ✅ Display Files to the Right of the Button */}
                <Grid mt={2} item xs={7} sm={7} md={8} lg={8}>
                  {Array.isArray(formik.values.fileName) && formik.values.fileName.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                        maxWidth: "100%", // Ensures wrapping when needed
                      }}
                    >
                      {formik.values.fileName.map((file, index) => (
                        <span
                          key={index}
                          style={{
                            fontSize: "1rem",
                            background: "#f0f0f0",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            whiteSpace: "nowrap", // Prevents breaking file names
                          }}
                        >
                          {file.name}
                          <IconButton size="small" onClick={() => handleRemoveFile(index)}>
                            <CancelIcon color="error" fontSize="small" />
                          </IconButton>
                        </span>
                      ))}
                    </div>
                  )}

                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={1}></Grid>

              <Grid item xs={12} sm={12} md={1}></Grid>

              <Grid item xs={3} sm={3} md={3} lg={3}>
                <FormGroup className="file-browse-btn-div">
                  <label style={{ padding: "0.5rem" }} className="btn label-sm file-browse-btn">
                    Select File *
                    <TextField
                      key={12345}
                      type="file"
                      name="fileName"
                      style={{
                        display: "none",
                        marginTop: "27px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFileChange}
                      required={true}
                      size="small"
                      value=""
                    />
                  </label>
                  <span style={{
                    fontSize: "0.75rem",
                    color: "red",
                    marginLeft: "14px",
                    fontWeight: "400",
                    marginRight: "14px",
                    marginTop: "3px",
                    fontFamily: "sans-serif"
                  }}>
                    {formik.errors.fileName}
                  </span>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={7}
                sm={7}
                md={7}
                lg={7}
                style={{ marginTop: "1.2rem", marginLeft: "1.5rem" }}
              >
                {formik.values?.fileName?.name ?? ""}
                {formik.values?.fileName?.name && (
                  <IconButton onClick={handleClear}>
                    <CancelIcon color="error" />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>

              <Grid item xs={12} sm={12} md={1}></Grid> */}
            </Grid>
            <Box textAlign="center" sx={{ mt: "20px" }}>
              <FormSubmitButton
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                loading={props?.loading}
              >
                Submit

              </FormSubmitButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </PopUpModel>
  );
  // } catch (err) {
  //   console.log(err);
  //   return <CommonErrorMessage />;
  // }
};

const mapStateToProps = (state) => ({
  crmLeads: state.crmLeads,
  crmLeadAttachment: state.crmLeadAttachment,
  backlogAttachments: state.backlogAttachments,
  projectBacklog: state.projectBacklog,
});

export default connect(mapStateToProps, {

})(AddAttachments);
