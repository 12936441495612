import React from "react";
// material ui
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

// ------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      fontFamily: "Arial",
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(0, 0,  0)",
    },
    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
      fontFamily: "Arial",
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(0, 0, 0)",
    },
    "& .css-1z7n62": {
      fontFamily: "Arial",
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(0, 0, 0)",
    },
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      fontFamily: "Arial",
      fontSize: "1rem",
      fontWeight: 400,
    },
    "& .MuiInputLabel-asterisk": {
      color: "red", // Apply red color to the asterisk
    },
  },
}));

// ------------------------------------------------------------

const FormTextField = ({
  label,
  type,
  id,
  name,
  value,
  onChange,
  required,
  fullWidth,
  size,
  margin,
  variant,
  disabled,
  error,
  helperText,
  multiline,
}) => {
  const classes = useStyles();
  return (
    <TextField
      label={label}
      multiline={multiline}
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      fullWidth={fullWidth}
      size={size}
      margin={margin}
      variant={variant}
      disabled={disabled}
      error={error}
      helperText={helperText}
      className={classes.root}
      sx={{ marginBottom: 0 }}
    ></TextField>
  );
};

export default FormTextField;
