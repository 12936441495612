import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import { MuiDataGrid } from "../../../components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { getToggleUserData } from "../../../features/auth/authSlice";
import {getClientForManager,getManagerCoachingFocusById,
  getManagerPurpose} from "../../../features/manager/coachingFocus/coachingManagerSlice";
import { GridCellExpand } from "../../../components";
import { Grid } from "@mui/material";
import { format } from "date-fns";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const Assesment = () => {
  const [accountName, setAccountName] = useState("");
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [allValue, setAllValue] = useState("");
  const [holder, setHolder] = useState("");
  const dispatch = useDispatch();

  const {getManagerPurposeData,areaFocusData} = useSelector((state) => ({ ...state.ManagerCoachingFocus }));

  const { toggleUserData } = useSelector((state) => ({...state.auth,}));

  const handleChange = (e) => {
    let clinetId = e.target.value.split(",")[0];
    let account = e.target.value.split(",")[1];
    let holderName = e.target.value.split(",")[2];
    setAllValue(e.target.value);
    setClientName(clinetId);
    setHolder(holderName);
    setAccountName(account);
    dispatch(getManagerCoachingFocusById(clinetId));
  };

  function renderCellExpand(params) {
   
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const mentorGetDevValue = (params) => {

    return params?.row?.developmentAreas
      ?.developmentAreaName;
  };

  const mentorGetCoachingValue = (params) => {
    return params?.row?.developmentAreasCoachingFocus?.
      developmentAreaCoachingFocusName
};
const getCreatedOnDate = (params) => {
  return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
};

const getUpdatedOnDate = (params) => {
  return params.row.updatedOn
    ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
    : ""

};

  const data = {
    rows: areaFocusData,
    columns: [
      {
        field: "developmentArea",
        headerName: "Leadership Dimension",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: mentorGetDevValue,
      },
     
        {
          field: "focus",
          headerName: "Sub-Category",
          width: 200,
          renderCell: renderCellExpand,
          valueGetter: mentorGetCoachingValue,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.subCategory ? false :  true
         
        },

      {
        field: "otherDevelopmentAreaCoachingFocus",
        headerName: "Focus Area",
        width: 150,
        // valueGetter: mentorGetCoachingValue,
        renderCell: renderCellExpand,
      },
        {
          field: "expectedOutcome",
          headerName: "Expected Outcome",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.expectedOutcome ? false :  true
        },
        {
          field: "anticipatedStartDate",
          headerName: "Anticipated Start Date",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.anticipatedStartDate ? false :  true
        },

      {
        field: "isDateAdded",
        headerName: "Date Added",

        width: 150,
        valueGetter: getCreatedOnDate
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: getManagerPurposeData,
    columns: [
      {
        width: 900,
        field: "purposeOfEngagment",
        headerName: "Engagement Purpose Description",
        renderCell: renderCellExpand,
      },
      {
        field: "isDateAdded",
        headerName: "Date Added",
        width: 150,
        valueGetter: getCreatedOnDate,

      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,
        valueGetter: getUpdatedOnDate,

      },

    ],
  };

  useEffect(() => {
    clientName && dispatch(getManagerCoachingFocusById(clientName?.id));
    dispatch(getManagerPurpose(clientName?.id));
  }, [clientName]);

  useEffect(() => {
    dispatch(getClientForManager());
  }, []);

  useEffect(() => {
   
    dispatch(getToggleUserData(clientName?.id));
  }, []);
  return (
    <Layout>
      <Stack>
      <Stack sx={{mt: 2,mb: 2,display: "flex",justifyContent: "center",alignItems: "center",flexDirection: "row",
            fontFamily: ["dinBold", "sans-serif"],
          }}>

          <Typography sx={{ fontSize: "1.5rem", fontWeight: "700" }} component="div">
          Purpose of Engagement for{" "}
            {clientName && `${clientName?.firstName} ${clientName?.lastName}`}{" "}
          </Typography>

        </Stack>
      </Stack>

      <Stack>
        <MuiDataGrid  
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          isRowHeight={true}   
          data={dataTwo} 
          />
      </Stack>
      {clientName && (
          <Stack sx={{mt: 2,mb: 2,display: "flex",justifyContent: "center",alignItems: "center",flexDirection: "row",
            fontFamily: ["dinBold", "sans-serif"],
          }}
        >
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "700" }} component="div">
            {" "}
            Focus Areas for {clientName?.firstName} {clientName?.lastName}{" "}
          </Typography>
        </Stack>
      )}

      {clientName && (
        <Stack>
          <MuiDataGrid data={data} />
        </Stack>
      )}
    </Layout>
  );
};

export default Assesment;
