import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

// material ui
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// components
import {MuiDataGrid,GridCellExpand} from "../../../components";
import Layout from "../../../layout/Layout";

import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";

import { getEnagagementScopeDetails } from "../../../features/hr/engagement/engagementSlice";
import Attachments from "../../../components/common/upload_documents/Attachments";
import { getHrEngagementDocuments, getChrNominationDocuments, createHrEngagementDocuments, deleteHRDocuments, resetErrMessage, resetMessage } from '../../../features/hr/dashboard/engagementHrDocumentSlice';
import { NewCommonGridData } from "../../../components/common/NewCommonGridData";

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
  },
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "40.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

// -----------------------------------------------------------------------------

const EngagementDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );

  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const keyEngResp = useSelector((state) => ({ ...state.keyEngagement }));
  const netScoreResp = useSelector((state) => ({ ...state.netPromoterScore }));
  const { engStageData } = useSelector((state) => ({ ...state.engStage }));

  const { keyClientAllEngagementData } = useSelector((state) => ({...state.hrEngagement}));

  const {
    message,
    errMessage,
    engagementsDocumentsData,
    engagmentChrHrDocumentData,
    loading,
  } = useSelector((state) => ({ ...state.HREngagementDocuments }));

  function getEngDate(params) {
    return params?.row?.engagementStartDate
      ? format(new Date(params?.row?.engagementStartDate), "MM-dd-yyyy")
      : "";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const keyEngagementData = {
    rows:
      keyClientAllEngagementData?.data?.length > 0
        ? keyClientAllEngagementData?.data[0]?.clientEngagement
          ? keyClientAllEngagementData?.data[0]?.clientEngagement
              ?.keyClientEngagements
          : []
        : [],
    columns: [
      {
        field: "engagementStageName",
        headerName: "Event Name",
        width: 140,
      },
      {
        field: "engagementDate",
        headerName: "Date",
        width: 140,
        valueGetter: getEngDate,
      },
      { field: "status", headerName: "Status", width: 140 },
      {
        field: "comments",
        headerName: "Comments",
        width: 140,
        renderCell: renderCellExpand,
      },
    ],
  };

  const handleDeleteKeyEng = () => {
    let data = {
      id: keyEngResp.selectedData?.id,
    };
    dispatch(deleteKeyEngagement(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          setKeyEngConfirmDialog({
            ...keyEngConfirmDialog,
            isOpen: false,
          });
          dispatch(keyEngResetMessage());
          dispatch(getKeyEngagement(props?.values?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(keyEngResetErrMessage());
        }, 3000);
      }
    });
  };

  useEffect(() => {
    dispatch(getEnagagementScopeDetails(clientName?.id));
  }, []);

  let deliverables =
    keyClientAllEngagementData?.length > 0 &&
    keyClientAllEngagementData[0]?.clientEngagement
      ?.clientEngagementDeliverableMapping?.length &&
    keyClientAllEngagementData[0]?.clientEngagement?.clientEngagementDeliverableMapping?.reduce(
      (acc, data) => {
        acc += `${data?.engagementDeliverable?.deliverableName}, `;
        return acc;
      },
      ""
    );

  let engagementData =
    keyClientAllEngagementData?.data?.length > 0 &&keyClientAllEngagementData?.data[0];

  //Left side column
  const LeaderData = [
    {
      heading: "Leader Name",
      data:
        engagementData?.excoCustomerUserDetail?.firstName ||
        engagementData?.excoCustomerUserDetail?.lastName
          ? `${engagementData?.excoCustomerUserDetail?.firstName || ""} ${
              engagementData?.excoCustomerUserDetail?.lastName || ""
            }`.trim()
          : "N/A",
    },
    {heading: "Company/Organization",data: engagementData?.excoCustomerAccount?.accountName,},
    {heading: "Title/Position",data: engagementData?.excoCustomerUserDetail?.designation,},
    { heading: "Department", data: engagementData?.clientEngagement?.department },
    { heading: "Level", data: engagementData?.clientEngagement?.level },
    { heading: "Employee ID", data: engagementData?.clientEngagement?.empId },
    { heading: "City/Location", data: null },
    {heading: "Offering Type",data: engagementData?.clientEngagement?.engTierName,},
    { heading: "Contract Duration", data: engagementData?.clientEngagement?.contractDuration },
    {heading: "Deliverables",data: engagementData?.clientEngagement?.delievrables,},
    { heading: "Initial Fees", data: engagementData?.clientEngagement?.mentorLaunchFee },
    { heading: "Renewal Fees", data: engagementData?.clientEngagement?.monthlyFee },
    { heading: "Travel & Related Expenses", data: engagementData?.clientEngagement?.travelAndRelatedExpenses },
  ];

  const MentoDetailsData = [
    {
      heading: "Mentor Name",
      data:
        engagementData?.clientEngagement?.clientMentorMapping?.excoMentor
          ?.excoMentorDetail?.firstName ||
        engagementData?.clientEngagement?.clientMentorMapping?.excoMentor
          ?.excoMentorDetail?.lastName
          ? `${
              engagementData?.clientEngagement?.clientMentorMapping?.excoMentor
                ?.excoMentorDetail?.firstName || ""
            } ${
              engagementData?.clientEngagement?.clientMentorMapping?.excoMentor
                ?.excoMentorDetail?.lastName || ""
            }`.trim()
          : "N/A",
    },
    {heading: "Mentor Email",data: engagementData?.clientEngagement?.clientMentorMapping?.excoMentor?.email},
  ];
  const KeyStakeHoldersData = [
    { heading: "Leader's Assistant", data: null },
    { heading: "Leader's Assistant Email", data: null },
    { heading: "Manager", data: null },
    { heading: "Manager Email", data: null },
    { heading: "HR Partner", data: null },
    { heading: "HR Partner Email", data: null },
    {
      heading: "Additional HR Contacts",
      data: keyClientAllEngagementData?.getAllChr
        ?.map(
          (item) =>
            `${item?.excoCustomerUserDetail?.firstName || ""} ${
              item?.excoCustomerUserDetail?.lastName || ""
            }`
        )
        .filter(Boolean) // Remove empty values
        .map((member, index, array) => (
          <div key={index}>
            {member}
            {index !== array.length - 1 ? "," : ""}
            <br />
          </div>
        )),
    },
  ];

  //Right side column
  const AccountData = [
    {
      heading: "Professional Team Member",
      data: [
        engagementData?.excoCustomerAccount?.professionalTeamMember1,
        engagementData?.excoCustomerAccount?.professionalTeamMember2,
        engagementData?.excoCustomerAccount?.professionalTeamMember3,
      ]
        .filter(Boolean) // Remove undefined or empty values
        .map((member, index, array) => (
          <div key={index}>
            {member}
            {index !== array.length - 1 ? "," : ""}
          </div>
        )),
    },
    {heading: "Lead Mentor",data: engagementData?.excoCustomerAccount?.leadMentor,},
    {heading: "Executive Sponsor",data: engagementData?.excoCustomerAccount?.executiveSponsor,},
  ];

  const KeyReferenceData = [
    {
      heading: "Launch/Start Date",
      data: engagementData?.clientEngagement?.engagementStartDate
        ? format(
            new Date(engagementData?.clientEngagement?.engagementStartDate),
            "MM-dd-yyyy"
          )
        : "",
    },
    {
      heading: "Renewal/End Date",
      data: engagementData?.clientEngagement?.engagementEndDate
        ? format(
            new Date(engagementData?.clientEngagement?.engagementEndDate),
            "MM-dd-yyyy"
          )
        : "",
    },
    {
      heading: "Next Update Due ",
      data: engagementData?.clientEngagement?.nextUpdateDue
        ? format(
            new Date(engagementData?.clientEngagement?.nextUpdateDue),
            "MM-dd-yyyy"
          )
        : "",
    },
  ];

  return (
    <>
      <Layout showDetails={true}>
        {!edit && (
          <Card sx={{ marginTop: "0.5rem" }}>
            <CardHeader
              title={
                <PageHeader variant="pageHeader">
                  Engagement Details for{" "}
                  {engagementData?.excoCustomerUserDetail?.firstName}{" "}
                  {engagementData?.excoCustomerUserDetail?.lastName}
                </PageHeader>
              }
            />
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12}
                sx={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                <Box
                  sx={{
                    width: "100%",
                    height: "3rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{border: "1px solid #b9b9b9",borderRadius: "3px",padding: "1rem"}}>
                    Current Stage:{" "}
                    {(
                      engagementData?.clientEngagement?.engagementStatus ===
                    "Pending Start/Potential Client"
                      ? "Potential Client"
                      : engagementData?.clientEngagement?.engagementStatus
                    )?.toUpperCase()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <CardContent>
              {/* <Stack flexDirection="row" justifyContent="space-between">
                <PageHeader variant="pageSecHeader">
                  {engagementData?.clientEngagement?.engagementStatus}
                </PageHeader>
                {location?.state?.permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "update"
                ) && (
                    <IconButton onClick={() => setEdit(true)}>
                      <EditIcon color="#00527d" />
                    </IconButton>
                  )}
              </Stack> */}

              <Grid container sx={{ marginTop: "1rem" }}>
                <Grid item xs={12} sm={12} md={0.3} lg={0.3}></Grid>
                <Grid item xs={12} sm={12} md={5.55} lg={5.55}>
                  {/* Leader Engagement Details page*/}
                  <NewCommonGridData
                    header="Leader/Engagement Details"
                    heading={LeaderData[0].heading}
                    data={LeaderData[0].data}
                    lastRow={LeaderData.length === 1} // If there's only one row, it's the last one
                  />
                  {LeaderData.slice(1).map((item, index) => {
                    const lastRow = index === LeaderData.length - 2; //Checks if it's the last row
                    return (
                      <NewCommonGridData
                        key={index}
                        heading={item.heading}
                        data={item.data}
                        lastRow={lastRow} // Pass this as a prop to conditionally apply styles
                      />
                    );
                  })}{" "}
                  {/* Mentor Details page*/}
                  <Box sx={{ marginTop: "1rem" }}>
                    <NewCommonGridData
                      header="Mentor Details"
                      heading={MentoDetailsData[0].heading}
                      data={MentoDetailsData[0].data}
                      lastRow={MentoDetailsData.length === 1} // If there's only one row, it's the last one
                    />
                    {MentoDetailsData.slice(1).map((item, index) => {
                      const lastRow = index === MentoDetailsData.length - 2; //Checks if it's the last row
                      return (
                        <NewCommonGridData
                          key={index}
                          heading={item.heading}
                          data={item.data}
                          lastRow={lastRow} // Pass this as a prop to conditionally apply styles
                        />
                      );
                    })}{" "}
                  </Box>
                  {/* Key stakeholders page*/}
                  <Box sx={{ marginTop: "1rem" }}>
                    <NewCommonGridData
                      header="Key Stakeholder Details"
                      heading={KeyStakeHoldersData[0].heading}
                      data={KeyStakeHoldersData[0].data}
                      lastRow={KeyStakeHoldersData.length === 1} // If there's only one row, it's the last one
                    />
                    {KeyStakeHoldersData.slice(1).map((item, index) => {
                      const lastRow = index === KeyStakeHoldersData.length - 2; //Checks if it's the last row
                      return (
                        <NewCommonGridData
                          key={index}
                          heading={item.heading}
                          data={item.data}
                          lastRow={lastRow} // Pass this as a prop to conditionally apply styles
                        />
                      );
                    })}{" "}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={0.3} lg={0.3}></Grid>
                <Grid item xs={12} sm={12} md={5.55} lg={5.55}
                  sx={{display: "flex",flexDirection: "column",justifyContent: "space-between"}}>
                  {/* Leader Engagement Details page*/}
                  <div>
                    <NewCommonGridData
                      header="ExCo Account Team"
                      heading={AccountData[0].heading}
                      data={AccountData[0].data}
                      lastRow={AccountData.length === 1} // If there's only one row, it's the last one
                    />
                    {AccountData.slice(1).map((item, index) => {
                      const lastRow = index === AccountData.length - 2; //Checks if it's the last row
                      return (
                        <NewCommonGridData
                          key={index}
                          heading={item.heading}
                          data={item.data}
                          lastRow={lastRow}
                        />
                      );
                    })}{" "}
                    <Box sx={{ marginTop: "1rem" }}>
                      <NewCommonGridData
                        header="Key Reference Dates"
                        heading={KeyReferenceData[0].heading}
                        data={KeyReferenceData[0].data}
                        lastRow={KeyReferenceData.length === 1} // If there's only one row, it's the last one
                      />
                      {KeyReferenceData.slice(1).map((item, index) => {
                        const lastRow = index === KeyReferenceData.length - 2; //Checks if it's the last row
                        return (
                          <NewCommonGridData
                            key={index}
                            heading={item.heading}
                            data={item.data}
                            lastRow={lastRow}
                          />
                        );
                      })}{" "}
                    </Box>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Box
                        sx={{display: "flex",justifyContent: "start",alignItems: "start"}}>
                        <Typography
                          sx={{fontWeight: "700",fontSize: "1.25rem",padding: "0.5rem",paddingLeft: "0rem"}}>
                          Engagement Timeline
                        </Typography>
                      </Box>
                      <MuiDataGrid
                        setShowDelete={() =>
                          setKeyEngConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => handleDeleteKeyEng(),
                          })
                        }
                        hideFooter={true}
                        toolbarColumn={true}
                        toolbarFilter={true}
                        toolbarDensity={true}
                        toolbarExport={true}
                        data={keyEngagementData}
                        select={(data) => dispatch(keyEngRowSelect(data))}
                        height={360}
                        autoHeight={false}
                      />
                    </Box>
                  </div>
                  <Box sx={{marginTop: "1rem"}}>
                    <NewCommonGridData
                      header="Net Promoter Score"
                      columns={["Type of Call", "NPS Score"]}
                      rows={[
                        ["Debrief/Check-In", "-"],
                        ["Close Out", "-"],
                      ]}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={0.3}></Grid>
                <Grid container sx={{ marginTop: "1rem" }}>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                  <Grid item xs={12} sm={12} md={11.4}>
                    <NewCommonGridData
                      header="Engagement Notes"
                      paragraph={
                        engagementData?.clientEngagement?.comments || "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                </Grid>

                <Grid container sx={{ marginTop: "1rem" }}>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                  <Grid item xs={12} sm={12} md={11.4}>
                    <NewCommonGridData
                      header="Reason for Mentoring/Coaching"
                      paragraph={
                        engagementData?.nomination?.reasonForCoaching || "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                  <Grid item xs={12} sm={12} md={11.4}>
                    <Stack sx={{paddingTop: "1rem"}}>
                      <PageHeader variant="pageHeader">
                        {" "}
                        Upload Documents{" "}
                      </PageHeader>
                    </Stack>
                    <Attachments
                      showAdd={false}
                      selectedDocumentType="LEADER_CONTEXT_MATERIALS"
                      loading={loading}
                      format=".doc, .docx, .pdf, .ppt, .pptx,  .jpg, .jpeg, .png"
                      viewableDirectly=".PDF, .JPG, .JPEG, .PNG"
                      downloadToView=".DOC, .DOCX, .PPT, .PPTX"
                      typeKey="leadersContextMaterials"
                      fixedKey="LEADER_CONTEXT_MATERIALS"
                      attachmentType="Leaders Bio,Org Charts & Context Materials"
                      clientEngagementId={engagementData?.clientEngagement?.id}
                      clientId={engagementData?.id}
                      createDocument={createHrEngagementDocuments}
                      getDocuments={getHrEngagementDocuments}
                      getHrDocuments={getChrNominationDocuments}
                      deleteDocument={deleteHRDocuments}
                      resetMessage={resetMessage}
                      resetErrMessage={resetErrMessage}
                      message={message}
                      errMessage={errMessage}
                      viewData={[
                        ...engagementsDocumentsData?.map((item) => ({
                          ...item,
                          isEngagement: true,
                        })),
                        ...engagmentChrHrDocumentData?.map((item) => ({
                          ...item,
                          isEngagement: false,
                        })),
                      ]}
                      dynamicPath="/file-acl/hr/view/common/"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={0.3}></Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {keyEngResp?.errMessage && (
          <ErrorNotifier message={keyEngResp?.errMessage} />
        )}
        {keyEngResp?.message && <Notifier message={keyEngResp?.message} />}
      </Layout>
    </>
  );
};

export default EngagementDetails;
