import {
  BASE_URL,
  MICRO_SITE_URL,
  MICRO_SITE_MOCK_URL,
  ROLE_URL,
  BASE_HR_URL,
} from "./config";

// ----------------------------------------------------------
// login authentication apis
export const LOGGED_IN_MICROSOFT = `${BASE_URL}/oauth-provider/microsoft-entra/oauth/initiate`;
export const LOGGED_IN_GOOGLE = `${BASE_URL}/oauth-provider/google/oauth/initiate`;
export const EMAIL_VERIFY = `${BASE_URL}/authentication/verify/login`;

export const GET_OAUTH_PROVIDERS = `${BASE_URL}/oauth-provider/fetch-all/oauth-providers`;
export const CUSTOMER_NAME = `${BASE_URL}/authentication/customer-name`;

export const CREATE_ACCOUNT = `${BASE_URL}/authentication/create`;

export const ACTIVATE_ACCOUNT = `${BASE_URL}/authentication/confirm-account`;

export const GET_ACCOUNTS = `${BASE_URL}/authentication/all/exco-admin`;

export const LOGIN_ACCOUNT = `${BASE_URL}/authentication/login`;

export const LOGOUT_ACCOUNT = `${BASE_URL}/authentication/logout`;

export const IS_LOGGED_IN = `${BASE_URL}/authentication/is-client-logged-in`;

export const LOGGED_IN_ROLES = `${BASE_URL}/authentication/role`;

export const UPLOAD_PROFILE_PIC = `${BASE_URL}/authentication/profile-pic`;

export const FORGOT_PASSWORD_EMAIL_VERIFY = `${BASE_URL}/authentication/forgot-password`;

export const FORGOT_PASSWORD_RESET = `${BASE_URL}/authentication/forgot-password/reset`;

export const CHECKING_CLIENT_IS_APPROVED = `${BASE_URL}/outcome/pending/impact-update`;

//customer creation

export const ADD_CUSTOMER_CREATION = `${BASE_URL}/customer`;

export const GET_CUSTOMERS = `${BASE_URL}/customer`;

//mentor creation

export const ADD_MENTOR_CREATION = `${BASE_URL}/mentor-onboarding`;

export const GET_MENTORS = `${BASE_URL}/mentor-onboarding`;

//microsite creation

export const ADD_MICROSITE_CREATION = `${MICRO_SITE_URL}/microsite`;

export const GET_MICROSITE_API = `${MICRO_SITE_URL}/microsite`;

export const GET_ALL_CUSTOMER_API = `${MICRO_SITE_URL}/customer`;

//get all roles

export const GET_ALL_ROLES = `${ROLE_URL}/customer/customer-client-roles`;

//dashbpard detail
export const UPLOAD_CLIENT_DOCUMENT = `${BASE_URL}/file-acl/client/upload/due-diligence-materials`;
export const GET_CLIENT_DOCUMENT = `${BASE_URL}/file-acl/client/fetch-all/engagement-side`;
export const DELETE_CLIENT_DOCUMENT = `${BASE_URL}/file-acl/client/delete/due-diligence-materials`;

export const GET_ENGAGEMENT_DETAIL = `${BASE_URL}/client-engagement`;
export const GET_CURRENT_MENTOR_NAME = `${BASE_URL}/client-mentor-mapping/client-mentor`;

export const GET_CLIENT_ENGAGEMENT_STAGE = `${BASE_URL}/client-engagement/latest`;

//assesment

export const GET_ASSESMENT_STRENGTH = `${BASE_URL}/assessment/strength/client`;
export const APPROVE_ASSESSMENT_STRENGTH = `${BASE_URL}/assessment/approve-strength`;

export const GET_ASSESMENT_IMPROVEMENT = `${BASE_URL}/assessment/area-of-improvement/client`;
export const APPROVE_ASSESSMENT_IMPROVEMENT = `${BASE_URL}/assessment/approve-area-of-improvement`;

export const GET_CLIENT_CORE_VALUES = `${BASE_URL}/leadership-core-value/client`;
export const APPROVE_LEADERSHIP_CORE_VALUES = `${BASE_URL}/leadership-core-value/approve-leadership-core-value`;

export const GET_APPROVE_BUTTON_HR = `${BASE_URL}/assessment/approve-button-name-hr`;
export const GET_APPROVE_BUTTON_MANAGER = `${BASE_URL}/assessment/approve-button-name-manager`;

export const UPDATE_PRIVATE_LEADERSHIP_IMPROVEMENT = `${BASE_URL}/assessment/update-to-private-area-of-improvement`;
export const UPDATE_LEADERSHIP_IMPROVEMENT = `${BASE_URL}/assessment/client/update-area-of-improvement`;
export const UPDATE_LEADERSHIP_STRENGTH = `${BASE_URL}/assessment/client/update-strength`;
export const UPDATE_PRIVATE_LEADERSHIP_STRENGTH = `${BASE_URL}/assessment/update-to-private-strength`;
export const UPDATE_PRIVATE_CORE_VALUES = `${BASE_URL}/leadership-core-value/update-to-private-leadership-core-value`;
export const UPDATE_PRIVATE_LEADERSHIP_COACHING_FOCUS = `${BASE_URL}/coaching-focus/update-to-private-coaching-focus`;
export const SHARE_WITH_HR = `${BASE_URL}/assessment/submit-assessment-hr`;
export const SHARE_WITH_MANAGER = `${BASE_URL}/assessment/submit-assessment-manager`;

// mulitiselct apis for assessment

export const UPDATE_PRIVATE_STRENGTH_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-private-strength`;
export const UPDATE_PUBLIC_STRENGTH_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-public-strength`;
export const UPDATE_APPROVE_STRENGTH_MULTI_SELECT = `${BASE_URL}/assessment//selected-update-to-appoved-strength`;

export const UPDATE_PRIVATE_IMPROVEMENT_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-private-area-of-improvement`;
export const UPDATE_PUBLIC_IMPROVEMENT_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-public-area-of-improvement`;
export const UPDATE_APPROVE_IMPROVEMENT_MULTI_SELECT = `${BASE_URL}/assessment//selected-update-to-appoved-area-of-improvement`;

//client Impact updates

export const UPDATE_IMPACT_UPDATES_DESCRIPTION = `${BASE_URL}/engagement-checkin-cfa`;
export const UPDATE_IMPACT_UPDATES_ENGAGEMENT_NOTES = `${BASE_URL}/engagement-checkin-note`;

//reports checkin

export const UPDATE_CLIENT_RATING_SUBMIT = `${BASE_URL}/client-progress-review/mentor/update-progress-rating`;

//rating
export const UPDATE_CLIENT_RATING_API = `${BASE_URL}/mentor-to-client-rating/client/update-outcome-id`;

//focus
export const UPDATE_FOCUS_AREA = `${BASE_URL}/coaching-focus/client/update-coaching-focus`;
export const GET_CLIENT_IMPROVEMENT = `${BASE_URL}/coaching-focus/client`;
export const GET_CLIENT_COACHING_PURPOSE = `${BASE_URL}/coaching-focus/get-purpose-of-engagement`;
export const APPROVE_CLIENT_IMPROVEMENT = `${BASE_URL}/coaching-focus/approve-coaching-focus`;
export const COACHING_SHARE_WITH_HR = `${BASE_URL}/coaching-focus/submit-coaching-focus-hr`;
export const COACHING_SHARE_WITH_MANAGER = `${BASE_URL}/coaching-focus/submit-coaching-focus-manager`;

export const GET_UPLOADED_DOCUMENTS_COACHING_FOCUS = `${BASE_URL}/coaching-focus/get-client-coaching-focus-document`;
export const GET_ALL_UPLOADED_DOCUMENTS_COACHING_FOCUS = `${BASE_URL}/file-acl/client/fetch/coaching-focus-side`;

export const UPDATE_PRIVATE_FOCUSAREA_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-private-coaching-focus`;
export const UPDATE_PUBLIC_FOCUSAREA_MULTI_SELECT = `${BASE_URL}/assessment/selected-update-to-public-coaching-focus`;
export const UPDATE_APPROVE_FOCUSAREA_MULTI_SELECT = `${BASE_URL}/assessment//selected-update-to-appoved-coaching-focus`;

export const GET_APPROVE_COACHING__BUTTON_HR = `${BASE_URL}/coaching-focus/approve-button-name-hr`;
export const GET_APPROVE_COACHING_BUTTON_MANAGER = `${BASE_URL}/coaching-focus/approve-button-name-manager`;

export const GET_DEVELOPMENT_AREA_COACHING_FOCUS = `${BASE_URL}/customer-development-areas/get-all`;
export const GET_FOCUS_AREA_BY_DEVELPMENT = `${BASE_URL}/development-areas-coaching-focus/get-all`;
//reports

export const GET_SUMMARY_DOCUMENTS_API = `${BASE_URL}/file-acl/client/fetch-all/summary-side`;
export const GET_REPORTS_AREA_FOCUS = `${MICRO_SITE_MOCK_URL}/area-of-focus/3dcbb765-20af-4a4a-9127-e84c81576faa`;
export const GET_REPORTS_ENGAGEMENT_STATUS = `${MICRO_SITE_MOCK_URL}/engagement-notes/3dcbb765-20af-4a4a-9127-e84c81576faa`;

// hr
export const HR_VIEW_DOCUMENTS_IMPACT_SUMMARY = `${BASE_URL}/file-acl/hr/fetch-all/summary-side`;

//nomination

export const CRUD_NOMINATION_HR = `${BASE_URL}/nomination/hr`;

//hr Leadership profile

export const GET_ALL_CLIENTS_HR = `${BASE_URL}/client-reporting-mapping/get-all-client-for-hr`;

export const GET_ALL_CLIENTS_HR_PURPOSE = `${BASE_URL}/coaching-focus/get-purpose-of-engagement-hr`;
export const GET_HR_CLIENT_STRENGTS_BY_ID = `${BASE_URL}/assessment/all-strength-for-hr`;
export const GET_HR_CLIENT_IMPROVEMENT_BY_ID = `${BASE_URL}/assessment/all-area-of-improvement-for-hr`;
export const GET_HR_CORE_VALUES_BY_ID = `${BASE_URL}/leadership-core-value/all-leadership-core-value-for-hr`;

//hr coaching focus

export const GET_HR_COACHING_FOCUS = `${BASE_URL}/coaching-focus/all-coaching-focus-for-hr`;

//hr dashboard latest

export const UPLOAD_HR_DOCUMENT = `${BASE_URL}/file-acl/client/upload/due-diligence-materials`;
export const GET_HR_DOCUMENT = `${BASE_URL}/v1/api/file-acl/client/view/due-diligence-materials`;

export const GET_Latest_CLIENT_HR = `${BASE_URL}/authentication/hr/top-five-client`;
export const GET_ACTIVE_PAST_ENGAGEMENT = `${BASE_URL}/client-engagement/get-active-and-past-engagement`;

//hr engagement scope

export const GET_HR_ENGAGEMENT_SCOPE_DETAILS = `${BASE_URL}/client-engagement/hr`;

// chr
export const CHR_VIEW_DOCUMENTS_IMPACT_SUMMARY = `${BASE_URL}/file-acl/chr/fetch-all/summary-side`;

//CHR nomination

export const ADD_NOMINATION_CREATION_CHR = `${BASE_URL}/nomination`;
export const DELETE_NOMINATION_CREATION_CHR = `${BASE_URL}/nomination`;

export const GET_NOMINATION_CHR = `${BASE_URL}/nomination/chr`;

export const All_Eng_History = `${BASE_URL}/client-reporting-mapping/get-all-client-history-for-chr`;
export const Active_Eng_Only = `${BASE_URL}/client-reporting-mapping/get-all-client-active-for-chr`;
export const Active_Last_Six_Mo = `${BASE_URL}/client-reporting-mapping/get-all-client-six-month-active-data-for-chr`;
export const Active_Last_Twelve_Mo = `${BASE_URL}/client-reporting-mapping/get-all-client-one-year-active-data-for-chr`;

//Chr Leadership profile

export const GET_ALL_CLIENTS_CHR = `${BASE_URL}/client-reporting-mapping/get-all-client-for-chr`;
export const GET_ALL_ENG_HISTORY_CLIENTS_CHR = `${BASE_URL}/client-reporting-mapping/get-all-client-history-for-chr`;
export const GET_ALL_CLIENTS_CHR_PURPOSE = `${BASE_URL}/coaching-focus/get-purpose-of-engagement-chr`;
export const GET_CHR_CLIENT_STRENGTS_BY_ID = `${BASE_URL}/assessment/all-strength-for-chr`;
export const GET_CHR_CLIENT_IMPROVEMENT_BY_ID = `${BASE_URL}/assessment/all-area-of-improvement-for-chr`;
export const GET_CHR_CORE_VALUES_BY_ID = `${BASE_URL}/leadership-core-value/all-leadership-core-value-for-chr`;

//Chr coaching focus

export const GET_CHR_COACHING_FOCUS = `${BASE_URL}/coaching-focus/all-coaching-focus-for-chr`;

//Chr dashboard latest

export const GET_Latest_CLIENT_CHR = `${BASE_URL}/authentication/hr/top-five-client`;
// export const GET_ACTIVE_PAST_ENGAGEMENT = `${BASE_URL}/client-engagement/get-active-and-past-engagement`;

//Chr engagement scope

export const GET_CHR_ENGAGEMENT_SCOPE_DETAILS = `${BASE_URL}/client-engagement/chr`;
export const CHR_RATING_BY_CLIENT = `${BASE_URL}/mentor-to-client-rating/report-chr`;
export const CHR_ENGAGEMENT_REPORTS = `${BASE_URL}/client-engagement-checkin/chr-report`;
export const CHR_PREVIOUS_OUTCOMES_DATA = `${BASE_URL}/outcome/chr/get-all-generate-outcome-date`;

export const CHR_LATEST_SUMMARY = `${BASE_URL}/summary/chr/latest-summary`;
export const CHR_LATEST_OUTCOMES = `${BASE_URL}/outcome/chr/latest-outcome`;

export const CHR_GENERATE_SUMMARY = `${BASE_URL}/summary/chr/generate-summary`;

export const CHR_PREVIOUS_OUTCOMES_DATA_BY_ID = `${BASE_URL}/outcome/hr/get-generated-outcome-by-id`;

export const CHR_PREVIOUS_SUMMARY_DATA = `${BASE_URL}/summary/hr/get-all-generate-summary-date`;

export const CHR_PREVIOUS_SUMMARY_DATA_BY_ID = `${BASE_URL}/summary/hr/get-generated-summary-by-id`;

//chr engagement Documents
export const CHR_ENGAGEMENT_UPLOAD_DOCUMENTS = `${BASE_URL}/file-acl/chr/upload/leaders-context-materials`;
export const CHR_ENGAGEMENT_GET_DOCUMENTS = `${BASE_URL}/file-acl/chr/fetch-all/nomination-side`;
export const CHR_NOMINATED_ENGAGEMENT_GET_DOCUMENTS = `${BASE_URL}/file-acl/chr/fetch/nomination-side`;
export const CHR_GET_DOCUMENTS = `${BASE_URL}/file-acl/chr/fetch-all/engagement-side`;
export const CHR_ENGAGEMENT_DELETE_DOCUMENTS = `${BASE_URL}/file-acl/chr/delete/nomination-side`;

//manager Leadershsip Profile
export const MANAGER_VIEW_DOCUMENTS_IMPACT_SUMMARY = `${BASE_URL}/file-acl/manager/fetch-all/summary-side`;
export const MANAGER_VIEW_DOCUMENTS_ENGAGEMENT_DETAILS = `${BASE_URL}/file-acl/manager/fetch-all/engagement-side`;

export const GET_ALL_CLIENTS_MANAGER = `${BASE_URL}/client-reporting-mapping/get-all-client-for-manager`;
export const GET_ALL_CLIENTS_MANAGER_PURPOSE = `${BASE_URL}/coaching-focus/get-purpose-of-engagement-manager`;
export const GET_MANAGER_CLIENT_STRENGTS_BY_ID = `${BASE_URL}/assessment/all-strength-for-manager`;
export const GET_MANAGER_CLIENT_IMPROVEMENT_BY_ID = `${BASE_URL}/assessment/all-area-of-improvement-for-manager`;
export const GET_MANAGER_CORE_VALUES_BY_ID = `${BASE_URL}/leadership-core-value/all-leadership-core-value-for-manager`;
//manager dashboard
export const GET_MANAGER_DASHBOARD_KEY_ENGAGEMENT = `${BASE_URL}/client-engagement/manager`;
export const GET_MANAGER_DASHBOARD_LATEST_ENGAGEMENT = `${BASE_URL}/authentication/manager/top-five-client`;

//manager Coaching focus

export const GET_MANAGER_FOCUS_BY_ID = `${BASE_URL}/coaching-focus/all-coaching-focus-for-manager`;

//client Rating
export const CLIENT_LATEST_OUTCOMES = `${BASE_URL}/outcome/client/latest-outcome`;
export const GET_ALL_PREVIOUS_OUTCOMES_DATA = `${BASE_URL}/outcome/client/get-all-generate-outcome-date`;
export const CLIENT_PREVIOUS_OUTCOMES_DATA_BY_ID = `${BASE_URL}/outcome/client/get-generated-outcome-by-id`;
export const SHARE_WITH_HR_OUTCOMES = `${BASE_URL}/outcome/client/share-with-hr`;
export const SHARE_WITH_MANAGER_OUTCOMES = `${BASE_URL}/outcome/client/share-with-manager`;
export const ACCEPT_BY_CLIENT_OUTCOME = `${BASE_URL}/outcome/client/approve-outcome`;

// duplicates
export const HR_RATING_BY_CLIENT = `${BASE_URL}/mentor-to-client-rating/report-hr`;
export const GET_RATING_BY_CLIENT = `${BASE_URL}/mentor-to-client-rating/report-client`;
export const MANAGER_RATING_BY_CLIENT = `${BASE_URL}/mentor-to-client-rating/report-manager`;

//client reports

export const ACCEPT_BY_CLIENT_SUMMARY = `${BASE_URL}/summary/client/approve-summary`;
export const CLIENT_LATEST_SUMMARY = `${BASE_URL}/summary/client/latest-summary`;

export const CLIENT_GENERATE_SUMMARY = `${BASE_URL}/summary/client/generate-summary`;

export const GET_ALL_PREVIOUS_SUMMARY_DATA = `${BASE_URL}/summary/client/get-all-generate-summary-date`;
export const SHARE_WITH_HR_SUMMARY = `${BASE_URL}/summary/client/share-with-hr`;
export const SHARE_WITH_MANAGER_SUMMARY = `${BASE_URL}/summary/client/share-with-manager`;
export const CLIENT_PREVIOUS_SUMMARY_DATA_BY_ID = `${BASE_URL}/summary/client/get-generated-summary-by-id`;

//duplicates
export const CURRENT_ROLES = `${BASE_URL}/authentication/current-user`;
export const CLIENT_ENGAGEMENT_REPORTS = `${BASE_URL}/summary/client-report`;
export const HR_ENGAGEMENT_REPORTS = `${BASE_URL}/client-engagement-checkin/hr-report`;

//hr engagement Documents
export const HR_ENGAGEMENT_UPLOAD_DOCUMENTS = `${BASE_URL}/file-acl/hr/upload/leaders-context-materials`;
export const HR_ENGAGEMENT_GET_DOCUMENTS = `${BASE_URL}/file-acl/hr/fetch-all/engagement-side`;
export const CHR_HR_NOMINATION_GET_DOCUMENTS = `${BASE_URL}/file-acl/hr/fetch-all/nomination-side`;
// export const HR_ENGAGEMENT_VIEW_FILE = `${BASE_URL}/file-acl/chr/view/common`;
export const HR_ENGAGEMENT_DELETE_DOCUMENTS = `${BASE_URL}/file-acl/hr/delete/engagement-side`;

export const DELETE_HR_NOMINATION_DOCUMENTS = `${BASE_URL}/file-acl/hr/delete/nomination-side`;

//hr summary
export const HR_PREVIOUS_SUMMARY_DATA = `${BASE_URL}/summary/hr/get-all-generate-summary-date`;
export const HR_LATEST_SUMMARY = `${BASE_URL}/summary/hr/latest-summary`;
export const HR_PREVIOUS_SUMMARY_DATA_BY_ID = `${BASE_URL}/summary/hr/get-generated-summary-by-id`;

export const HR_GENERATE_SUMMARY = `${BASE_URL}/summary/hr/generate-summary`;

//hr outcomes
export const HR_PREVIOUS_OUTCOMES_DATA = `${BASE_URL}/outcome/hr/get-all-generate-outcome-date`;
export const HR_LATEST_OUTCOMES = `${BASE_URL}/outcome/hr/latest-outcome`;
export const HR_PREVIOUS_OUTCOMES_DATA_BY_ID = `${BASE_URL}/outcome/hr/get-generated-outcome-by-id`;

//manager summary
export const MANAGER_PREVIOUS_SUMMARY_DATA = `${BASE_URL}/summary/manager/get-all-generate-summary-date`;
export const MANAGER_LATEST_SUMMARY = `${BASE_URL}/summary/manager/latest-summary`;
export const MANAGER_PREVIOUS_SUMMARY_DATA_BY_ID = `${BASE_URL}/summary/manager/get-generated-summary-by-id`;

export const MANAGER_GENERATE_SUMMARY = `${BASE_URL}/summary/manager/generate-summary`;

//Manager engagement Documents

export const MANAGER_ENGAGEMENT_DOCUMENTS_GET = `${BASE_URL}/summary/hr/get-all-generate-summary-date`;

//Manager outcomes
export const MANAGER_PREVIOUS_OUTCOMES_DATA = `${BASE_URL}/outcome/manager/get-all-generate-outcome-date`;
export const MANAGER_LATEST_OUTCOMES = `${BASE_URL}/outcome/manager/latest-outcome`;
export const MANAGER_PREVIOUS_OUTCOMES_DATA_BY_ID = `${BASE_URL}/outcome/manager/get-generated-outcome-by-id`;

// notification
export const GET_ALL_NOTIFICATION = `${BASE_URL}/notification-service`;
export const UPDATE_NOTIFICATION_ALL = `${BASE_URL}/notification-service/all-read`;
export const UPDATE_NOTIFICATION_BY_ID = `${BASE_URL}/notification-service`;
export const GET_LVA = `${BASE_URL}/lva-document/get-client-lva-document`;

//toggle
export const GET_TOGGLE_DATA = `${BASE_URL}/microsite-feature-flags`;
export const GET_TOGGLE_USER_DATA = `${BASE_URL}/microsite-feature-flags/users`;

//

export const GET_ALL_HR_PRIVATE_FEEDBACK_API = `${BASE_URL}/chat-mentor-hr-manager-chr/hr/chat-message`;
export const CREATE_PRIVATE_FEEDBACK_API_HR = `${BASE_URL}/chat-mentor-hr-manager-chr/hr/send-message`;
export const UPDATE_PRIVATE_FEEDBACK_API_HR = `${BASE_URL}/chat-mentor-hr-manager-chr/hr/update-message`;
export const DELETE_PRIVATE_FEEDBACK_API_HR = `${BASE_URL}/chat-mentor-hr-manager-chr/hr/delete-message`;

export const GET_ALL_MANAGER_PRIVATE_FEEDBACK_API = `${BASE_URL}/chat-mentor-hr-manager-chr/manager/chat-message`;
export const CREATE_PRIVATE_FEEDBACK_API_MANAGER = `${BASE_URL}/chat-mentor-hr-manager-chr/manager/send-message`;
export const UPDATE_PRIVATE_FEEDBACK_API_MANAGER = `${BASE_URL}/chat-mentor-hr-manager-chr/manager/update-message`;
export const DELETE_PRIVATE_FEEDBACK_API_MANAGER = `${BASE_URL}/chat-mentor-hr-manager-chr/manager/delete-message`;

export const GET_ALL_CHR_PRIVATE_FEEDBACK_API = `${BASE_URL}/chat-mentor-hr-manager-chr/chr/chat-message`;
export const CREATE_PRIVATE_FEEDBACK_API_CHR = `${BASE_URL}/chat-mentor-hr-manager-chr/chr/send-message`;
export const UPDATE_PRIVATE_FEEDBACK_API_CHR = `${BASE_URL}/chat-mentor-hr-manager-chr/chr/update-message`;
export const DELETE_PRIVATE_FEEDBACK_API_CHR = `${BASE_URL}/chat-mentor-hr-manager-chr/chr/delete-message`;
