import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";

export const NewCommonGridData = ({ header, heading, data,columns=[],rows=[],paragraph,lastRow }) => {
    return (
      <Grid container spacing={2}>
        {/* Display header only if it exists */}
        {header && (
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
              {header} {/*Use header for displaying data once*/}
            </Typography>
          </Grid>
        )}
  
        {/* Table structure for key-value pairs */}
        {heading && (
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <TableContainer sx={{ 
            border: "1px solid #b9b9b9", 
            borderBottom:"0px solid #b9b9b9",
            }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize:"1rem",
                      fontWeight: 400,
                      width: "45%",//adjust the width for the left columns
                      borderRight: "1px solid #b9b9b9", // Vertical line separator
                      borderBottom: lastRow ? "1px solid #b9b9b9" : "0px solid transparent", // Conditionally apply border
                      padding:"0.5rem", // padding inside the data
                      verticalAlign:"top",//alligns the heading in the top
                      wordBreak: "break-word", // Forces breaking long words
                      overflowWrap: "break-word", // Alternative for better browser support
                    }}
                    >
                    {heading}
                  </TableCell>
  
                  {/* Data Column */}
                  <TableCell sx={{
                    borderBottom: lastRow ? "1px solid #b9b9b9" : "0px solid transparent", // Conditionally apply border
                    padding:"0.5rem",
                    fontSize:"1rem",
                    wordBreak: "break-word", // Forces breaking long words
                    overflowWrap: "break-word", // Alternative for better browser support
                    }}>
                    {data || "N/A"}
                    </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
         )}

        {/* Table structure for displaying column header */}
        {columns.length > 0 && rows.length > 0 && (
      <Grid item xs={12}>
        <TableContainer sx={{ border: "1px solid #b9b9b9",borderRight:"0px" }}>
          <Table>
            <TableBody>
              {/* Render column headers */}
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize:"18px",
                      padding:"0.5rem",
                      width:"50%",
                      fontWeight: "bold",
                      backgroundColor: "#ffffff",
                      borderRight: "1px solid #b9b9b9", // Vertical line separator
                      borderBottom: "0px solid #b9b9b9",
                    }}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>

              {/* Render rows */}
              {rows.map((row, index) => (
                <TableRow key={index}>
                  {row.map((cell, cellIndex) => (
                    <TableCell 
                    key={cellIndex} 
                    sx={{ 
                    padding:"0.5rem",
                    fontSize:"1rem",
                    borderTop:"1px solid #b9b9b9",
                    borderRight: "1px solid #b9b9b9", // Vertical line separator
                    borderBottom: "0px solid #b9b9b9",
                     }}
                     >
                      {cell || "-"}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      )}

        {/* paragrpah for displaying data in a box*/}
        {paragraph && (
          <Grid item xs={12} lg={12} md={12}>
            <Box sx={{ padding: 2, border: "1px solid #b9b9b9",borderRadius:0, backgroundColor: "#ffffff" }}>
              <Typography sx={{ fontSize: "1rem" }}>
                {paragraph}
              </Typography>
            </Box>
          </Grid>
        )}

      </Grid>
    );
  };
  //apply ebebeb styles for the MUI data grid color